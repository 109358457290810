import { CalendlyAppointment } from "../api-objects/CalendlyAppointment";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ErrorWithMessageCallback, GetAppointmentsSuccessCallback, handleApiResponse } from "./callback-helpers";

export async function getAppointmentsByOrganizerEmail(
    idToken: string,
    email: string,
    onSuccess: GetAppointmentsSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void
) {
    try {
        const response = await fetch(
            `${BASE_URL}/api/booking/appointments/organizer/${email}`,
            requestOptionsGET(idToken)
        );
        await handleApiResponse<CalendlyAppointment[]>(response, onSuccess, onError, handleSessionExpiry);
    } catch (error) {
        console.error("Get appointments failed:", error);
        onError("An unexpected error occurred");
    }
}

export async function getAppointmentsByOrganizerEmail2(
    idToken: string,
    email: string,
    handleSessionExpiry?: () => void
): Promise<CalendlyAppointment[] | null> {
    try {
        const response = await fetch(
            `${BASE_URL}/api/booking/appointments/organizer/${email}`,
            requestOptionsGET(idToken)
        );
        if (!response.ok) {
            if (response.status === 401 && handleSessionExpiry) {
                handleSessionExpiry(); // Handle session expiry if needed
            }
            if (response.status === 404) {
                // Handle 404 if no appointments are found
                console.warn(`No appointments found for organizer with email ${email}.`);
                return []; // Returning an empty array instead of null
            }

            const errorMessage = await response.text();
            throw new Error(`Failed to fetch appointments: ${response.statusText}. ${errorMessage}`);
        }
        return await response.json()
    } catch (error) {
        console.error("Get appointments failed:", error);
        return null;
    }
}

export async function getAppointmentsByUserEmail(
    idToken: string,
    email: string,
    onSuccess: GetAppointmentsSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void
) {
    try {
        const response = await fetch(
            `${BASE_URL}/api/booking/appointments/${email}`,
            requestOptionsGET(idToken)
        );
        await handleApiResponse<CalendlyAppointment[]>(response, onSuccess, onError, handleSessionExpiry);
    } catch (error) {
        console.error("Get appointments failed:", error);
        onError("An unexpected error occurred");
    }
}

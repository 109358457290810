import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Patient } from '../../../utils/api-objects/Patient';
import { useAuth } from '../../../utils/context/AuthContext';
import { useCreateSession } from '../hooks/useCreateSession';
import useGetChatSessions from '../hooks/useGetChatSessions';
import { ChatSession } from '../types/ChatSession';
import { UnreadMessagesCounter } from './UnreadMessagesCounter';
interface Props {
  patient: Patient;
}

export const ChatPreview: FC<Props> = ({ patient }) => {
  const { auth } = useAuth();
  const role = auth.role;
  const navigate = useNavigate();
  const { data: chatSessions, isFetching } = useGetChatSessions();
  const { mutate, isError, isSuccess, data } = useCreateSession(
    patient.user_id,
    role!,
  );
  const userSession = chatSessions?.find(
    (session: ChatSession) => session.user_id === patient.user_id,
  );

  const handleClick = () => {
    if (userSession) {
      navigate(`/chat-session/${userSession.id}`);
    } else {
      if (!isFetching) {
        mutate();
      }
    }
  };

  // TODO-TM: Improve Error UI
  if (isError) {
    return <div>Errore durante la creazione della sessione</div>;
  }

  if (isSuccess && data) {
    navigate(`/chat-session/${data.id}`);
  }

  // TODO-TM: Improve notification red dot

  return (
    <div
      className='w-full p-[20px] rounded-[5px] border border-solid border-holifya-grey-shadow cursor-pointer shadow-platform-new flex items-center justify-between relative'
      onClick={handleClick}
    >
      <div>
        <div className='font-semibold text-[18px]'>
          {patient.user_name} {patient.user_family_name}
        </div>
        <div className='font-semibold text-[12px] text-holifya-blue'>
          {patient.email}
        </div>
      </div>
      <div className='flex items-center gap-2'>
        {userSession && <UnreadMessagesCounter sessionId={userSession.id} />}
        <ArrowIcon />
      </div>
    </div>
  );
};

interface ArrowIconProps extends React.SVGProps<SVGSVGElement> {}

const ArrowIcon: React.FC<ArrowIconProps> = (props) => {
  return (
    <svg
      width='5'
      height='7'
      viewBox='0 0 5 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props} // This allows passing additional props like className, style, etc.
    >
      <path
        d='M0.686208 0.196422C0.566943 0.320837 0.5 0.489138 0.5 0.664567C0.5 0.839995 0.566943 1.0083 0.686208 1.13271L2.95305 3.5166L0.686209 5.86729C0.566943 5.9917 0.5 6.16001 0.5 6.33543C0.5 6.51086 0.566943 6.67916 0.686209 6.80358C0.745738 6.86582 0.816561 6.91522 0.894593 6.94893C0.972626 6.98264 1.05632 7 1.14086 7C1.22539 7 1.30909 6.98264 1.38712 6.94893C1.46515 6.91522 1.53598 6.86582 1.5955 6.80358L4.31059 3.98807C4.3706 3.92634 4.41824 3.85289 4.45075 3.77197C4.48326 3.69106 4.5 3.60426 4.5 3.5166C4.5 3.42894 4.48326 3.34215 4.45075 3.26123C4.41824 3.18031 4.3706 3.10687 4.31059 3.04514L1.5955 0.196422C1.53598 0.134182 1.46515 0.0847821 1.38712 0.0510697C1.30909 0.0173578 1.22539 -3.17078e-08 1.14086 -2.80127e-08C1.05632 -2.43177e-08 0.972625 0.0173578 0.894593 0.0510697C0.816561 0.0847821 0.745737 0.134182 0.686208 0.196422Z'
        fill='black'
      />
    </svg>
  );
};

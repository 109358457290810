import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Loader from "../../../components/Loader"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { getAllSortingItemsApi } from "../../../utils/apis/order-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { calculateDateRanges, processItems } from "../../../utils/Functions"
import { Events } from "./Components/Contents"

const Analytics = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [isShopifyLoading, setIsShopifyLoading] = useState(false)
    const [purchasesLength, setPurchasesLength] = useState<number>()
    const [purchasesThisMonthLength, setPurchasesThisMonthLength] = useState<number>()
    const [purchasesPreviusMonthLength, setPurchasesPreviousMonthLength] = useState<number>()
    const [purchasesThisWeekLength, setPurchasesThisWeekLength] = useState<number>()
    const [purchasesPreviousWeekLength, setPurchasesPreviousWeekLength] = useState<number>()

    useEffect(() => {
        if (!idToken) return

        setIsLoading(true)
        setIsShopifyLoading(true)

        const dateRanges = calculateDateRanges(new Date());

        getAllSortingItemsApi(
            idToken,
            (items) => {
                const { totalItems, thisMonth, lastMonth, thisWeek, lastWeek } = processItems(items, dateRanges);

                setPurchasesLength(totalItems)
                setPurchasesThisMonthLength(thisMonth)
                setPurchasesPreviousMonthLength(lastMonth)
                setPurchasesThisWeekLength(thisWeek);
                setPurchasesPreviousWeekLength(lastWeek);

                setIsLoading(false)
                setIsShopifyLoading(false)
            },
            (error) => {
                console.error("Error fetching sorting items:", error);
                setIsLoading(false)
                setIsShopifyLoading(false)
            },
        )
    }, [idToken])

    const analyticsBoxes = [
        {
            onClick: () => navigate("purchases"),
            content: isShopifyLoading ? (
                <Loader />
            ) : (
                <div className="space-y-[20px]">
                    <div className="font-bold text-[14px] leading-[20px]">Acquisti su shopify</div>
                    <div className="grid grid-cols-3 gap-1 font-medium text-[10px] leading-[17px]">
                        <div>Totali: {purchasesLength}</div>
                        <div className="space-y-[5px]">
                            <div>Questo mese: {purchasesThisMonthLength}</div>
                            <div>Mese precedente: {purchasesPreviusMonthLength}</div>
                            <div>MoM value:
                                <span className={`${purchasesThisMonthLength! - purchasesPreviusMonthLength! < 0 ? "text-holifya-red" : "text-black"}`}>
                                    {purchasesPreviusMonthLength === 0
                                        ? " N/A"
                                        : ` ${(((purchasesThisMonthLength! - purchasesPreviusMonthLength!) / purchasesPreviusMonthLength!) * 100).toFixed(2)}%`}
                                </span>
                            </div>
                        </div>
                        <div className="space-y-[5px]">
                            <div>Questa settimana: {purchasesThisWeekLength}</div>
                            <div>Settimana precedente: {purchasesPreviousWeekLength}</div>
                            <div>Wow value:
                                <span className={`${purchasesThisWeekLength! - purchasesPreviousWeekLength! < 0 ? "text-holifya-red" : "text-black"}`}>
                                    {purchasesPreviousWeekLength === 0
                                        ? " N/A"
                                        : ` ${(((purchasesThisWeekLength! - purchasesPreviousWeekLength!) / purchasesPreviousWeekLength!) * 100).toFixed(2)}%`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            onClick: () => navigate("events"),
            content: <Events/>
        },
    ]

    // ********************************************************************************

    if (isLoading) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div className="w-full h-full flex flex-col pl-[70px]">
            <div className="font-bold text-[35px] leading-[55px] my-[30px]">
                Analytics
            </div>
            <div className="w-[89%] flex flex-col flex-1">
                <div className={`grid grid-cols-2 gap-4`}>
                    {analyticsBoxes.map((box, index) => (
                        <AnalyticsBox key={index} onClick={box.onClick}>
                            {box.content}
                        </AnalyticsBox>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Analytics

interface Props {
    onClick: () => void,
    children: React.ReactNode,
}

const AnalyticsBox: React.FC<Props> = ({
    onClick,
    children,
}) => {
    return (
        <div className="w-full p-[20px] rounded-[15px] shadow-platform cursor-pointer hover:bg-holifya-grey" onClick={onClick}>
            {children}
        </div>
    )
}

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../../components/buttons/new/BackButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { MealPlan } from "../../../../utils/api-objects/meal-plan";
import { Patient } from "../../../../utils/api-objects/Patient";
import { getMealPlansApi } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { usePatients } from "../../../../utils/context/PatientsContext";
import { getFormattedDateWithInput } from "../../../../utils/Functions";

const MealPlanHistory = () => {
    const { auth } = useAuth()
        const idToken = auth.idToken
        const location = useLocation();
        const navigate = useNavigate()
    
        const searchParams = new URLSearchParams(location.search);
        const patientId = searchParams.get("patient_id");
    
        const { patientsState } = usePatients()
        const patients = patientsState.patients
    
        const [patient, setPatient] = useState<Patient | null>(null);
        const [isGeneralLoading, setIsGeneralLoading] = useState(true);
    
        const [mealPlans, setMealPlans] = useState<MealPlan[]>()

        useEffect(() => {
                if (!patients) {
                    setIsGeneralLoading(false);
                    return;
                }
                const foundPatient = patients.find((p) => p.user_id === patientId);
                if (!foundPatient) return
                setPatient(foundPatient);
                setIsGeneralLoading(false)
        
                if (!patientId || !idToken) return
            }, [patients, patientId])

            const callGetMealPlans = async () => {
                    if (!patientId || !idToken) {
                        console.log(`patientId or idToken missing: patientId: ${patient}, idToken: ${idToken}`)
                        return;
                    }
                    try {
                        const mealPlans = await getMealPlansApi(idToken, patientId)
                        setMealPlans(mealPlans)
                    } catch {
                        console.log("Errore nel recupero del meal plan")
                    }
                }

                useEffect(() => {
                    callGetMealPlans()
                }, [])

                if (isGeneralLoading) {
                        return (
                            <LoadingSpinner />
                        );
                    }

                    if (!patient) {
                        return (
                            <div className="w-full h-full flex flex-col items-center">
                                <div>
                                    Informazioni sul cliente non trovate
                                </div>
                                <button
                                    onClick={() => navigate("/platform-experts/customers")}
                                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                                >
                                    Torna a clienti
                                </button>
                            </div>
                        );
                    }

                    if (!mealPlans) {
                        return (
                            <div>
                                
                            </div>
                        )
                    }
  return (
    <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
      <div className="w-full  max-w-[1068px] space-y-[20px]">
                <BackButton
                    text={`Torna a Meal Plan`}
                    onClick={() => navigate(`/platform-experts/customers/single-customer/view-meal-plan-page?patient_id=${patientId}`)}
                />
                <div className="space-y-[10px]">
                    <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                    <div className="font-bold text-[35px] leading-[35px]">Meal Plan passati</div>
                </div>
                {
                mealPlans && mealPlans.map((plan, index) => {
                    if (index !== 0) {
                        const currentDate = getFormattedDateWithInput(plan.created_at);
                        const prevDate = getFormattedDateWithInput(mealPlans[index - 1].created_at);
                        const spanDate = `${currentDate} <> ${prevDate}`

                        return (
                            <div
                                key={plan.id}
                                className="w-full h-[111px] rounded-[5px] flex items-center justify-center self-center flex-shrink-0 shadow-platform cursor-pointer p-[20px]"
                                onClick={() => navigate(`old-meal-plan?id=${plan.id}&span-date=${spanDate}&patient-id=${patientId}`)}
                            >
                                <div className="w-full">
                                    <div className="font-medium text-[14px] leading-[33px]">
                                        {spanDate}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })
            }
            </div>
    </div>
  )
}

export default MealPlanHistory
function getMealPlanApi(idToken: string, patientId: string) {
    throw new Error("Function not implemented.");
}


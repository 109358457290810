import { FC, useRef, useState } from 'react';
import { useSendMessage } from '../../hooks/useSendMessage';

interface Props {
  chatSessionId: string;
}
export const SendBar: FC<Props> = ({ chatSessionId }) => {
  const [message, setMessage] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { sendMessage, isPending, isError } = useSendMessage(chatSessionId, () => {
    setMessage('');
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.style.height = '40px';
        inputRef.current.focus();
      }
    }, 0);
  },
  );

  // TODO-TM: Improve Error UI
  if (isError) {
    return <div>Error sending message</div>;
  }

  const isMobile = () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleSendMessage = () => {
    if (message.replace(/\s+/g, '').length > 0) {
      sendMessage(message); // Send the message as-is
      setMessage(''); // Clear input after sending
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.style.height = '40px'; // Reset textarea height
          inputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleResize = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    const maxHeight = 100;
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!isMobile()) {
      if (e.key === 'Enter') {
        if (e.shiftKey) {
          e.preventDefault();
          setMessage((prev) => {
            const newMessage = prev + '\n';
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.style.height = 'auto';
                inputRef.current.style.height = `${Math.min(
                  inputRef.current.scrollHeight,
                  100
                )}px`;
                inputRef.current.scrollTop = inputRef.current.scrollHeight;
              }
            }, 0);
            return newMessage;
          });
        } else if (!isPending) {
          e.preventDefault();
          handleSendMessage();
        }
      }
    }
  };

  return (
    <div className='w-full h-auto pl-[75px] pr-[70px]'>
      <div className='w-full flex items-end flex gap-2 border border-solid border-gray-300 rounded-[15px] p-2 shadow-platform-new'>
        <textarea
          ref={inputRef}
          placeholder='Scrivi'
          className='w-full p-2 disabled:opacity-50 disabled:animate-pulse focus:outline-none resize-none'
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
            handleResize(e);
          }}
          disabled={isPending}
          onKeyDown={handleKeyDown}
          rows={1}
        />
        <button
          className='bg-primary-action h-[36px] rounded-[18px] text-white px-6 py-2 disabled:opacity-50 disabled:animate-pulse font-bold text-[14px]'
          onClick={handleSendMessage}
          disabled={isPending || message.trim().length === 0}
        >
          Invia
        </button>
      </div>
    </div>
  );
};

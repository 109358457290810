import { useEffect } from 'react';
import useGetChatSessions from '../hooks/useGetChatSessions';
import { useMessages } from '../hooks/useMessages';
import { Message } from '../types/Message';
interface Props {
  sessionId: string;
}

export const UnreadMessagesCounter = ({ sessionId }: Props) => {
  const { data: sessions, refetch, isFetching } = useGetChatSessions();
  const { messages } = useMessages(sessionId, false, 15000);
  useEffect(() => {
    refetch();
  }, [refetch]);
  const currentSession = sessions?.find((session) => session.id === sessionId);
  const unreadMessages = messages?.filter(
    (message: Message) =>
      new Date(message.created_at) >
      new Date(currentSession?.last_read_expert || 0),
  );
  if (unreadMessages?.length === 0 || isFetching) {
    return null;
  }
  return (
    <div className='text-white bg-holifya-red rounded-full w-4 h-4 flex items-center justify-center p-3 font-bold text-[12px]'>
      {unreadMessages?.length}
    </div>
  );
};

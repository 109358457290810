export enum ExpertRole {
    Nutritionist = "nutritionist",
    Specialist = "specialist",
    Admin = "admin",
    User = "user",
    Physician = "physician",
}

export enum ExpertGender {
    Male = "M",
    Female = "F",
}

export const enum CompleteAccountPageState {
    NoEdit = "no-edit",
    Edit = "edit",
}

export enum AppointmentStatus {
    Active = "active",
    Canceled = "canceled",
}

export enum Folder {
    HealthAssessment = "health-assessment",
    DnaTest = "dna-test",
    BloodTest = "blood-test",
    ResultsAndProgresses = "results-and-progresses",
    SharedFromEquipe = "shared-from-equipe",
    SharedFromPatient = "shared-from-patient",
    Nutritionist = "nutritionist",
    HealthPlan = "health-plan",
    MealPlan = "meal-plan",
}

/**
* Enum for the collecting process
* @readonly
* @enum {string}
*/
export enum TrackingStatus {
    /** @member {string} */
    /** The user has completed the purchase */
    Purchased = "purchased",
    /** @member {string} */
    /** The user has received the test at home */
    Delivered = "delivered",
    /** @member {string} */
    /** The user has performed the cheeck swab */
    TestDone = "test_done",
    /** @member {string} */
    /** The user has registered the swab */
    SampleRegistered = "sample_registered",
    /** @member {string} */
    /** The user has packed and requested the pickup */
    PickupRequested = "pickup_requested",
    /** @member {string} */
    /** The pack has been taken from the transport company */
    Departed = "departed",
    /** @member {string} */
    /** The sample is at the laboratory */
    DeliveredToLaboratory = "delivered_to_laboratory",
    StartingToExtract = "starting_to_extract",
    StartingAnalysis = "starting_analysis",
    AnalysisDone = "analysis_done",
    ReportGenerated = "report_generated"
}

export enum BucketFolder {
    DnaExams = "dna_exam",
    BloodExams = "blood_exam",
    OtherExams = "other_exam",
    Plan = "plan",
    CrossResults = "cross_results",
    HealthPlan = "health_plan",
    Diet = "diet",
    ShoppingList = "shopping_list",
    Eatinghabits = "eating_habits",
    TipsAndRecepes = "tips_and_recepes",
    FruitsAndVegetables = "seasonal_fruits_and_vegetables",
    UploadedFromUser = "from_user",
    UploadedFromExpert = "from_expert",
}

export enum BloodTestTrackingStatus {
    Purchased = "purchased",
    Delivered = "delivered",
    TestDone = "test-done",
    SampleRegistered = "sample-registered",
    PickupRequested = "pickup-requested",
    DeliveredToLab = "delivered-to-laboratory",
    ReportGenerated = "report-generated",
    None = "none",
}

export enum QuestionCategory {
    PersonalGoals = "personali_e_obiettivi",
    FamilyHistoryDiseases = "familiarita_patologie",
    LifestyleSports = "stile_di_vita_e_attivita",
    DietaryHabits = "abitudini_alimentari",
    Notes = "note",
}

export enum QuestionType {
    MultiSelect = "multi_select",
    Radio = "radio",
    SingleValueText = "single_value_text",
    SingleValueNumber = "single_value_number",
    OpenParagraph = "open_paragraph",
}

export enum QuestionScoreCategory {
    ScoreCategoryOne = "score_category_one",
    ScoreCategoryTwo = "score_category_two",
    ScoreCategoryThree = "score_category_three",
    ScoreCategoryFour = "score_category_four"
}

export enum Environment {
    DEVELOP = "develop",
    STAGE = "stage",
    PRODUCTION = "production",
}

export enum PdfType {
    DnaTest = "dna-test",
    BloodTest = "blood-test",
    CrossResults = "cross-results",
    HealthPlan = "health-plan",
    Diet = "diet",
    EatingHabits = "eating-habits",
    ShoppingList = "shopping-list",
    TipsAndRecepes = "tips-and-recepes",
    SeasonalFruitsAndVegetables = "seasonal-fruits-and-vegetables",
    Uploads = "uploads",
}

export enum PasswordInputIcon {
    ShowEnabled = "/images/forms/show-password-enabled.svg",
    ShowDisabled = "/images/forms/show-password-disabled.svg",
    HideEnabled = "/images/forms/hide-password-enabled.svg",
    HideDisabled = "/images/forms/hide-password-disabled.svg",
}

export enum PasswordInputType {
    Text = "text",
    Password = "password",
}

export enum SurveyType {
    HealthAssessment = "health_assessment",
    CheckupSurvey = "checkup_survey",
    AnswerUpdated = "answer_updated",
    AnswerOverwritten = "answer_overwritten",
}

import { useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { infulencersUserIds } from "../../../../utils/Constants";
import { TempStat } from "../../../../utils/api-objects/TempStat";
import { HealthPlan, MealPlan } from "../../../../utils/api-objects/meal-plan";
import { getAllHealthPlansApi, getAllMealPlansApi } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { InfoSection } from "./Utils.tsx/InfoSection";
import { StatType } from "./Utils.tsx/enums";
import { getHealthPlanInteractions, getHealthPlanInteractionsUniqueEmails, getMealPlanInteractions, getMealPlanInteractionsUniqueEmails, getWeeklyLoginStats, getWeeklyLoginWithHealthPlanStats, getWeeklyLoginWithMealPlanStats, getWeeklyUniqueEmails, getWeeklyUniqueEmailsWithHealthPlan, getWeeklyUniqueEmailsWithMealPlan } from "./Utils.tsx/functions";

export const LoginStats: React.FC<{
    stats: TempStat[]
}> = ({ stats }) => {
    const [selectedWeek, setSelectedWeek] = useState<{ start: Date; end: Date; label: string } | null>(null);

    const totalLogins = selectedWeek ? getWeeklyLoginStats(stats, selectedWeek) : 0;
    const uniqueUsers = selectedWeek ? getWeeklyUniqueEmails(stats, selectedWeek) : 0;
    const avgLoginsPerUser = uniqueUsers ? (totalLogins / uniqueUsers).toFixed(2) : "0.00";

    const [showInfo, setShowInfo] = useState(false)

    return (
        <div>
            <div className="w-full p-[20px] shadow-platform rounded-[15px] space-y-[20px]">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="font-bold text-lg">
                            Login generale
                        </div>
                        <img
                            className="ml-[20px] cursor-pointer"
                            src="/images/symbols/info-logo.svg"
                            onClick={() => setShowInfo(true)}
                        />
                    </div>
                    <WeekSelector selectedWeek={selectedWeek} onSelectWeek={setSelectedWeek} />
                </div>
                <div className="w-full flex justify-between items-center">
                    <div>
                        <div>Login totali settimanali: {totalLogins}</div>
                        <div>Unici user settimanali: {uniqueUsers}</div>
                    </div>

                    <div className="bg-holifya-red text-white font-bold w-[50px] h-[50px] flex items-center justify-center rounded-full text-[14px]">
                        {avgLoginsPerUser}
                    </div>
                </div>
            </div>
            {
                showInfo &&
                <InfoSection
                    onClick={() => setShowInfo(false)}
                    type={StatType.Login}
                />
            }
        </div>
    )
}

export const HealthPlanStats: React.FC<{
    stats: TempStat[]
}> = ({
    stats,
}) => {
        const { auth } = useAuth()
        const idToken = auth.idToken
        const [selectedWeek, setSelectedWeek] = useState<{ start: Date; end: Date; label: string } | null>(null);
        const [healthPlans, setHealthPlans] = useState<HealthPlan[]>()
        const [showInfo, setShowInfo] = useState(false)

        const totalLogins = selectedWeek ? getWeeklyLoginWithHealthPlanStats(stats, selectedWeek) : 0;
        const uniqueUsers = selectedWeek ? getWeeklyUniqueEmailsWithHealthPlan(stats, selectedWeek) : 0;
        const avgLoginsPerUser = uniqueUsers ? (totalLogins / uniqueUsers).toFixed(2) : "0.00";

        const totalHealPlanInteractions = selectedWeek ? getHealthPlanInteractions(stats, selectedWeek) : 0
        const uniqueUsersInteractingWithHealthPlan = selectedWeek ? getHealthPlanInteractionsUniqueEmails(stats, selectedWeek) : 0;
        const avgInteractionsPerUserWithHP = uniqueUsers ? (totalHealPlanInteractions / uniqueUsersInteractingWithHealthPlan).toFixed(2) : "0.00";

        useEffect(() => {
            const getAllHealthPlans = async () => {
                if (!idToken) return
                try {
                    const hp = await getAllHealthPlansApi(idToken)
                    const filteredHp = hp.filter(hp => !infulencersUserIds.includes(hp.user_id))
                    setHealthPlans(filteredHp)
                } catch (error) {
                    console.log(error)
                }
            }

            getAllHealthPlans()
        }, [])

        if (!healthPlans) {
            return (
                <Loader />
            )
        }

        return (
            <div>
                <div className="w-full p-[20px] shadow-platform rounded-[15px] space-y-[20px]">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <div className="font-bold text-lg">
                                Health Plan
                            </div>
                            <img
                                className="ml-[20px] cursor-pointer"
                                src="/images/symbols/info-logo.svg"
                                onClick={() => setShowInfo(true)}
                            />
                        </div>
                        <WeekSelector selectedWeek={selectedWeek} onSelectWeek={setSelectedWeek} />
                    </div>
                    <div className="w-full flex justify-between items-end">
                        <div className="w-full space-y-[20px]">
                            <div className="">
                                Health Plan totali: {healthPlans.length}
                            </div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <div className="font-bold text-[14px] text-holifya-blue">Login</div>
                                    <div className="font-normal text-[12px]">Login totali settimanali: {totalLogins}</div>
                                    <div className="font-normal text-[12px]">Unici user settimanali: {uniqueUsers}</div>
                                </div>
                                <div className="bg-holifya-red text-white font-bold w-[50px] h-[50px] flex items-center justify-center rounded-full text-[14px]">
                                    {avgLoginsPerUser}
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <div className="font-bold text-[14px] text-holifya-blue">Interazioni</div>
                                    <div className="font-normal text-[12px]">Interazioni settimanali con hp: {totalHealPlanInteractions}</div>
                                    <div className="font-normal text-[12px]">Unici user settimanali che interagiscono con hp: {uniqueUsersInteractingWithHealthPlan}</div>
                                </div>
                                <div className="bg-holifya-red text-white font-bold w-[50px] h-[50px] flex items-center justify-center rounded-full text-[14px]">
                                    {avgInteractionsPerUserWithHP}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showInfo &&
                    <InfoSection
                        onClick={() => setShowInfo(false)}
                        type={StatType.HealthPlan}
                    />
                }
            </div>
        )
    }

export const MealPlanStats: React.FC<{
    stats: TempStat[]
}> = ({
    stats,
}) => {
        const { auth } = useAuth()
        const idToken = auth.idToken
        const [selectedWeek, setSelectedWeek] = useState<{ start: Date; end: Date; label: string } | null>(null);
        const [mealPlans, setMealPlans] = useState<MealPlan[]>()
        const [showInfo, setShowInfo] = useState(false)

        useEffect(() => {
            const getAllMealPlans = async () => {
                if (!idToken) return
                try {
                    const mp = await getAllMealPlansApi(idToken)
                    setMealPlans(mp)
                } catch (error) {
                    console.log(error)
                }
            }

            getAllMealPlans()
        }, [])

        const mealPlansV1 = mealPlans?.filter(mp => mp.data.goal !== null)
        const mealPlansV2 = mealPlans?.filter(mp => mp.data.top_hero_foods !== null)
        const mealPlansV3 = mealPlans?.filter(mp => mp.data.guidelines !== null)

        const totalLogins = selectedWeek ? getWeeklyLoginWithMealPlanStats(stats, selectedWeek) : 0;
        const uniqueUsers = selectedWeek ? getWeeklyUniqueEmailsWithMealPlan(stats, selectedWeek) : 0;
        const avgLoginsPerUser = uniqueUsers ? (totalLogins / uniqueUsers).toFixed(2) : "0.00";

        const totalMealPlanInteractions = selectedWeek ? getMealPlanInteractions(stats, selectedWeek) : 0
        const uniqueUsersInteractingWithMealPlan = selectedWeek ? getMealPlanInteractionsUniqueEmails(stats, selectedWeek) : 0;
        const avgInteractionsPerUserWithHP = uniqueUsersInteractingWithMealPlan ? (totalMealPlanInteractions / uniqueUsersInteractingWithMealPlan).toFixed(2) : "0.00";

        return (
            <div className="w-full p-[20px] shadow-platform rounded-[15px] space-y-[20px]">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="font-bold text-lg">Meal Plan</div>
                        <img
                            className="ml-[20px] cursor-pointer"
                            src="/images/symbols/info-logo.svg"
                            onClick={() => setShowInfo(true)}
                        />
                    </div>
                    <WeekSelector selectedWeek={selectedWeek} onSelectWeek={setSelectedWeek} />
                </div>
                <div className="flex items-center text-[14px]">
                    <div className="">
                        Meal totali: {mealPlans?.length}
                    </div>
                    <div className="ml-[20px]">
                        <div>Meal Plan V1 totali: {mealPlansV1?.length}</div>
                        <div>Meal Plan V2 totali: {mealPlansV2?.length}</div>
                        <div>Meal Plan V3 totali: {mealPlansV3?.length}</div>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div>
                        <div className="font-bold text-[14px] text-holifya-blue">Login</div>
                        <div className="font-normal text-[12px]">Login totali settimanali: {totalLogins}</div>
                        <div className="font-normal text-[12px]">Unici user settimanali: {uniqueUsers}</div>
                    </div>
                    <div className="bg-holifya-red text-white font-bold w-[50px] h-[50px] flex items-center justify-center rounded-full text-[14px]">
                        {avgLoginsPerUser}
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div>
                        <div className="font-bold text-[14px] text-holifya-blue">Interazioni</div>
                        <div className="font-normal text-[12px]">Interazioni settimanali con mp: {totalMealPlanInteractions}</div>
                        <div className="font-normal text-[12px]">Unici user settimanali che interagiscono con mp: {uniqueUsersInteractingWithMealPlan}</div>
                    </div>
                    <div className="bg-holifya-red text-white font-bold w-[50px] h-[50px] flex items-center justify-center rounded-full text-[14px]">
                        {avgInteractionsPerUserWithHP}
                    </div>
                </div>
            </div>
        )
    }

export const WeekSelector: React.FC<{
    onSelectWeek: (week: { start: Date; end: Date; label: string }) => void;
    selectedWeek: { start: Date; end: Date; label: string } | null;
}> = ({ onSelectWeek, selectedWeek }) => {
    const [weekOptions, setWeekOptions] = useState<{ start: Date; end: Date; label: string }[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        const generateWeekOptions = () => {
            const options = [];
            let tempDate = new Date();
            tempDate.setDate(tempDate.getDate() - ((tempDate.getDay() + 6) % 7));

            const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };

            for (let i = 0; i < 30; i++) {
                const startOfWeek = new Date(tempDate);
                const endOfWeek = new Date(tempDate);
                endOfWeek.setDate(endOfWeek.getDate() + 6);

                options.push({
                    start: new Date(startOfWeek),
                    end: new Date(endOfWeek),
                    label: `${startOfWeek.toLocaleDateString('it-IT', dateOptions)} - ${endOfWeek.toLocaleDateString('it-IT', dateOptions)}`,
                });

                tempDate.setDate(tempDate.getDate() - 7);
            }

            setWeekOptions(options);
            onSelectWeek(options[0]); // Default to most recent week
        };

        generateWeekOptions();
    }, [onSelectWeek]);

    return (
        <div className="relative">
            <div className="w-[300px] h-[50px]">
                <PrimaryButton
                    text={selectedWeek ? selectedWeek.label : "Seleziona settimana"}
                    pointer={true}
                    disabled={false}
                    onClick={() => setShowDropdown(!showDropdown)}
                />
            </div>
            {showDropdown && (
                <div className="absolute top-[60px] right-0 bg-white border border-gray-300 rounded shadow-lg z-10 w-[200px] max-h-[200px] overflow-y-auto scrollbar-hide">
                    {weekOptions.map((option, index) => (
                        <div
                            key={index}
                            className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${selectedWeek?.label === option.label ? 'bg-blue-100' : ''}`}
                            onClick={() => {
                                onSelectWeek(option);
                                setShowDropdown(false);
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

import { TempStat } from "../../../../../utils/api-objects/TempStat"

// 🔹 Utility Functions for Statistics
const normalizeDate = (date: Date) => {
    const normalizedDate = new Date(date)
    normalizedDate.setHours(0, 0, 0, 0)
    return normalizedDate
}

const normalizeEndDate = (date: Date) => {
    const normalizedDate = new Date(date)
    normalizedDate.setHours(23, 59, 59, 999)
    return normalizedDate
}

export const getWeeklyLoginStats = (stats: TempStat[], week: { start: Date; end: Date }) => {
    const loginStats = stats.filter(stat => stat.event_type === "login_success")
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)

    return loginStats.reduce((total, stat) => {
        return total + stat.timestamps.filter(timestamp => {
            const date = new Date(timestamp.date)
            return date >= startOfWeek && date <= endOfWeek
        }).length
    }, 0)
}

export const getWeeklyUniqueEmails = (stats: TempStat[], week: { start: Date; end: Date }) => {
    const loginStats = stats.filter(stat => stat.event_type === "login_success")
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)
    const uniqueEmails = new Set<string>()

    loginStats.forEach(stat => {
        stat.timestamps.forEach(timestamp => {
            const timestampDate = new Date(timestamp.date)
            if (timestampDate >= startOfWeek && timestampDate <= endOfWeek) {
                uniqueEmails.add(stat.email)
            }
        })
    })

    return uniqueEmails.size
}

export const getWeeklyLoginWithHealthPlanStats = (
    stats: TempStat[],
    week: { start: Date; end: Date },
    excludedEmails: string[] = ["marchitommaso8@gmail.com", "brustia.marco@gmail.com", "silvia.fascians@yahoo.com"]
) => {
    const loginStats = stats.filter(
        stat => 
            stat.event_type === "login_success_with_health_plan" && 
            !excludedEmails.includes(stat.email)
    )
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)

    return loginStats.reduce((total, stat) => {
        return total + stat.timestamps.filter(timestamp => {
            const date = new Date(timestamp.date)
            return date >= startOfWeek && date <= endOfWeek
        }).length
    }, 0)
}

export const getWeeklyUniqueEmailsWithHealthPlan = (
    stats: TempStat[],
    week: { start: Date; end: Date },
    excludedEmails: string[] = ["marchitommaso8@gmail.com", "brustia.marco@gmail.com", "silvia.fascians@yahoo.com"]
) => {
    const loginStats = stats.filter(
        stat => 
            stat.event_type === "login_success_with_health_plan" &&
            !excludedEmails.includes(stat.email)
    )
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)
    const uniqueEmails = new Set<string>()

    loginStats.forEach(stat => {
        stat.timestamps.forEach(timestamp => {
            const timestampDate = new Date(timestamp.date)
            if (timestampDate >= startOfWeek && timestampDate <= endOfWeek) {
                uniqueEmails.add(stat.email)
            }
        })
    })

    return uniqueEmails.size
}

export const getWeeklyLoginWithMealPlanStats = (stats: TempStat[], week: { start: Date; end: Date }) => {
    const loginStats = stats.filter(stat => stat.event_type === "login_success_with_meal_plan_v3")
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)

    return loginStats.reduce((total, stat) => {
        return total + stat.timestamps.filter(timestamp => {
            const date = new Date(timestamp.date)
            return date >= startOfWeek && date <= endOfWeek
        }).length
    }, 0)
}

export const getWeeklyUniqueEmailsWithMealPlan = (stats: TempStat[], week: { start: Date; end: Date }) => {
    const loginStats = stats.filter(stat => stat.event_type === "login_success_with_meal_plan_v3")
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)
    const uniqueEmails = new Set<string>()

    loginStats.forEach(stat => {
        stat.timestamps.forEach(timestamp => {
            const timestampDate = new Date(timestamp.date)
            if (timestampDate >= startOfWeek && timestampDate <= endOfWeek) {
                uniqueEmails.add(stat.email)
            }
        })
    })

    return uniqueEmails.size
}

export const getHealthPlanInteractions = (
    stats: TempStat[],
    week: { start: Date; end: Date },
    excludedEmails: string[] = ["marchitommaso8@gmail.com", "brustia.marco@gmail.com", "silvia.fascians@yahoo.com"]
) => {
    const relevantEvents = [
        "open_health_plan_button_clicked",
        "show_less_food_suggested_button_clicked",
        "show_more_food_suggested_button_clicked",
        "show_less_integrators_suggested_button_clicked",
        "show_more_integrators_suggested_button_clicked",
        "show_less_check_ups_suggested_button_clicked",
        "show_more_check_ups_suggested_button_clicked",
        "show_less_comment_button_clicked",
        "show_more_comment_button_clicked",
    ]
    const regex = /^(toggle_on|untoggle_on)/
    const filteredStats = stats.filter(stat => 
        (relevantEvents.includes(stat.event_type) || regex.test(stat.event_type)) &&
        !excludedEmails.includes(stat.email)
    )
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)

    return filteredStats.reduce((total, stat) => {
        return total + stat.timestamps.filter(timestamp => {
            const date = new Date(timestamp.date)
            return date >= startOfWeek && date <= endOfWeek
        }).length
    }, 0)
}

export const getHealthPlanInteractionsUniqueEmails = (
    stats: TempStat[],
    week: { start: Date; end: Date },
    excludedEmails: string[] = ["marchitommaso8@gmail.com", "brustia.marco@gmail.com", "silvia.fascians@yahoo.com"]
) => {
    const relevantEvents = [
        "open_health_plan_button_clicked",
        "show_less_food_suggested_button_clicked",
        "show_more_food_suggested_button_clicked",
        "show_less_integrators_suggested_button_clicked",
        "show_more_integrators_suggested_button_clicked",
        "show_less_check_ups_suggested_button_clicked",
        "show_more_check_ups_suggested_button_clicked",
        "show_less_comment_button_clicked",
        "show_more_comment_button_clicked",
    ]
    const regex = /^(toggle_on|untoggle_on)/
    const filteredStats = stats.filter(stat => 
        (relevantEvents.includes(stat.event_type) || regex.test(stat.event_type)) &&
        !excludedEmails.includes(stat.email) // Exclude emails here
    )
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)
    const uniqueEmails = new Set<string>()

    filteredStats.forEach(stat => {
        stat.timestamps.forEach(timestamp => {
            const timestampDate = new Date(timestamp.date)
            if (timestampDate >= startOfWeek && timestampDate <= endOfWeek) {
                uniqueEmails.add(stat.email)
            }
        })
    })

    return uniqueEmails.size
}

export const getMealPlanInteractions = (stats: TempStat[], week: { start: Date; end: Date }) => {
    const relevantEvents = [
        "open_meal_plan_button_clicked",
        "show_less_guidelines_button_clicked",
        "show_more_guidelines_button_clicked",
        "show_less_recepees_button_clicked",
        "show_more_recepees_button_clicked",
        "show_less__button_clicked",
        "show_more__button_clicked",
    ]
    const regex = /^(toggle_on_guidelines|untoggle_on_guidelines|toggle_on_recepees|untoggle_on_recepees|toggle_on__|untoggle_on__|toggle_on_meal_plan|untoggle_on_meal_plan|show_more_meal_plan|show_less_meal_plan)/
    const filteredStats = stats.filter(stat => 
        relevantEvents.includes(stat.event_type) || regex.test(stat.event_type)
    )
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)

    return filteredStats.reduce((total, stat) => {
        return total + stat.timestamps.filter(timestamp => {
            const date = new Date(timestamp.date)
            return date >= startOfWeek && date <= endOfWeek
        }).length
    }, 0)
}

export const getMealPlanInteractionsUniqueEmails = (stats: TempStat[], week: { start: Date; end: Date }) => {
    const relevantEvents = [
        "open_meal_plan_button_clicked",
        "show_less_guidelines_button_clicked",
        "show_more_guidelines_button_clicked",
        "show_less_recepees_button_clicked",
        "show_more_recepees_button_clicked",
        "show_less__button_clicked",
        "show_more__button_clicked",
    ]
    const regex = /^(toggle_on_guidelines|untoggle_on_guidelines|toggle_on_recepees|untoggle_on_recepees|toggle_on__|untoggle_on__|toggle_on_meal_plan|untoggle_on_meal_plan|show_more_meal_plan|show_less_meal_plan)/
    const filteredStats = stats.filter(stat => 
        relevantEvents.includes(stat.event_type) || regex.test(stat.event_type)
    )
    const startOfWeek = normalizeDate(week.start)
    const endOfWeek = normalizeEndDate(week.end)
    const uniqueEmails = new Set<string>()

    filteredStats.forEach(stat => {
        stat.timestamps.forEach(timestamp => {
            const timestampDate = new Date(timestamp.date)
            if (timestampDate >= startOfWeek && timestampDate <= endOfWeek) {
                uniqueEmails.add(stat.email)
            }
        })
    })

    return uniqueEmails.size
}
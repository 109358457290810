export interface MealPlan {
    id: string,
    user_id: string,
    data: MealPlanData,
    pdf_data: MealPlanPdfData[],
    updates_history: MealPlanDataHistory[],
    created_at: string,
    updated_at: string,
}

export interface HealthPlan {
    id: string,
    user_id: string,
    data: HealthPlanData,
    pdf_data: PdfData[],
    updates_history: DataHistory[],
    created_at: string,
    updated_at: string,
}

export interface MealPlanData {
    guidelines: CheckableItem[], //v3
    recepees: CheckableItem[], //v3
    nutri_comment: string[], //v3
    food_tips: CheckableItem[], //v3
    integrators_suggested: CheckableItem[], //v3
    key_parameters: string[], //v2
    nutrition_recommendations: string[], //v2
    top_hero_foods: string[], //v2
    top_integratori: string[], //v2
    goal: string, //v1
    comment: string, //v1
    key_points: string[], //v1
    important_key_points: string[], //v1
}

interface MealPlanDataHistory {
    value: MealPlanData,
    date: string,
}

interface DataHistory {
    value: HealthPlanData,
    date: string,
}

export enum HealthPlanPdfType {
    Materials = "materials",
    Prescriptions = "prescriptions",
}

export enum MealPlanPdfType {
    MealPlan = "meal_plan",
    SupportMaterials = "support_materials",
}

export interface MealPlanPdfData {
    file_name: string,
    s3_url: string,
    type: MealPlanPdfType,
}

export interface PdfData {
    file_name: string,
    s3_url: string,
    type: HealthPlanPdfType,
}

export interface CheckableItem {
    text: string,
    isChecked: boolean,
}

export interface HealthPlanData {
    comment: string[],
    food_suggested: CheckableItem[],
    integrators_suggested: CheckableItem[],
    check_ups_suggested: CheckableItem[],
}
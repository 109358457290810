import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../utils/Constants';
import { useAuth } from '../../../utils/context/AuthContext';
import { Message } from '../types/Message';
const sendMessage = async ({
  chatSessionId,
  message,
  sender_id,
  userToken,
}: {
  chatSessionId: string;
  message: string;
  sender_id: string;
  userToken: string;
}) => {
  const response = await fetch(
    `${BASE_URL}/api/chat/sessions/${chatSessionId}/messages`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      },
      body: JSON.stringify({ message, sender_id }),
    },
  );

  if (!response.ok) {
    throw new Error('Failed to send message');
  }

  return response.json() as Promise<Message>;
};

export const useSendMessage = (
  chatSessionId: string,
  onComplete?: () => void,
) => {
  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const userToken = auth.idToken!;
  const { mutate, isPending, isError } = useMutation({
    mutationFn: (message: string) =>
      sendMessage({
        chatSessionId,
        message,
        sender_id: auth.userId!,
        userToken,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['messages', chatSessionId] });
      onComplete?.();
    },
  });

  return { sendMessage: mutate, isPending, isError };
};

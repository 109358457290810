export const DateSeparator: React.FC<{
  date: Date;
  formatDate?: (date: Date) => string;
}> = ({ date }) => {
  const isToday = (someDate: Date) => {
    const today = new Date();
    return (
      someDate.getFullYear() === today.getFullYear() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getDate() === today.getDate()
    );
  };

  let formattedDate;
  if (isToday(date)) {
    formattedDate = 'Oggi';
  } else {
    const day = date.getDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('it-IT', { month: 'short' })
      .format(date)
      .slice(0, 3);
    const year = date.getFullYear();

    formattedDate = `${day} ${month} ${year}`;
  }

  formattedDate = formattedDate.replace(/\./g, '').toLowerCase();

  return (
    <div className='flex flex-col items-center'>
      <div className='text-center bg-[#F5F5F5] text-[10px] font-bold rounded-2xl px-8 py-2'>
        {formattedDate}
      </div>
    </div>
  );
};

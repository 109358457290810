import { Message } from '../../types/Message';

interface Props {
  message: Message;
  isUser: boolean;
  isSending?: boolean;
  showTime?: boolean;
}

const formatDate = (dateString: string) => {
  try {
    const date = new Date(dateString + "Z");
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return date.toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  } catch (error) {
    return 'Invalid date';
  }
};

export const MessageBubble: React.FC<Props> = ({
  message,
  isUser,
  isSending,
  showTime,
}) => {
  return (
    <div className='w-full flex flex-col gap-2'>
      <div
        className={`w-full flex message-bubble ${
          isUser ? 'justify-end' : 'justify-start'
        }`}
      >
        <div
          className={`rounded-xl py-[9px] px-[15px] text-[14px] leading-[20px] max-w-[70%] flex ${
            isUser
              ? 'bg-[#C1D9FF] justify-end rounded-br-none'
              : 'bg-[#EEEEEE] justify-start rounded-bl-none'
          }`}
        >
          <p
            className='font-normal text-[14px] break-words break-all overflow-hidden'
            style={{ whiteSpace: 'pre-wrap' }}
          >{isSending ? 'Sending...' : message.message}</p>
        </div>
      </div>
      {showTime && <div
        className={`text-xs text-gray-500 ${
          isUser ? 'text-right' : 'text-left'
        }`}
      >
        {formatDate(message.created_at)}
      </div>}
    </div>
  );
};

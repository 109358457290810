import PrimaryButton from "../../../../../components/buttons/PrimaryButton"

export const InfoSection: React.FC<{
    onClick: () => void,
    type: string,
}> = ({
    onClick,
    type,
}) => {
        const InfoContent = infoComponents[type] || (() => <p>Invalid Type</p>)
        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-4 rounded-[15px] shadow-lg flex flex-col items-center w-[90%] md:w-[362px]">
                    <InfoContent />
                    <div className="w-full h-[50px] mt-[20px]">
                        <PrimaryButton
                            text={"Chiudi"}
                            pointer={true}
                            disabled={false}
                            onClick={onClick}
                        />
                    </div>
                </div>
            </div>
        )
    }

const LoginInfoBox = () => {
    return (
        <div className="w-full flex flex-col items-start space-y-[20px]">
            <span className="font-bold text-[14px]">
                Statistiche settimanali
            </span>
            <div>
                Vengono salvati i login totali basati sulla settimana scelta e il numero di utenti unici per settimana.
                Grazie a questo si può trovare il valore:<br /><br />
                <strong>Avg. logins per user per week</strong> =
                <strong> Total logins </strong> ÷ <strong> Total unique users</strong>
            </div>
        </div>
    )
}

const HealthPlanInfoBox = () => {
    return (
        <div className="w-full flex flex-col items-start space-y-[20px]">
            <span className="font-bold text-[14px]">
                Health Plan
            </span>
            <div className="space-y-[20px]">
                <div>
                    <div className="font-bold">
                        Logins
                    </div>
                    <div>
                        Vengono salvati i login totali di utenti che hanno un health plan basati sulla settimana scelta e il numero di utenti unici per settimana che hanno un health plan.
                        Grazie a questo si può trovare il valore:<br />
                        <strong>Avg. logins per user per week with hp</strong> =
                        <strong> Total logins with hp </strong> ÷ <strong> Total unique users with hp</strong>
                    </div>
                </div>
                <div>
                    <div className="font-bold">
                        Interactions
                    </div>
                    <div>
                        Vengono salvate le interazioni totali degli utenti con l'health plan:<br />
                        <ul className="list-disc ml-[20px]">
                            <li>Apri health plan da homepage</li>
                            <li>Show more / less dal box</li>
                            <li>Toggle / untoggle dal box</li>
                        </ul>
                        Calcoliamo poi le interazioni per persona <br/>
                        <strong>Avg. interactions per user per week with hp</strong> =
                        <strong>Total interactions with hp </strong> ÷ <strong> Total unique users with hp interacting</strong>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MealPlanInfoBox = () => {
    return (
        <>
        </>
    )
}

const infoComponents: { [key: string]: React.FC } = {
    login: LoginInfoBox,
    health_plan: HealthPlanInfoBox,
    meal_plan: MealPlanInfoBox,
};
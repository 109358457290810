import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import BackButton from "../../../../components/buttons/new/BackButton";
import { PdfType } from "../../../../utils/Enums";
import { Patient } from "../../../../utils/api-objects/Patient";
import { MealPlanPdfType } from "../../../../utils/api-objects/meal-plan";
import { getMealPlanPdf } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { usePatients } from "../../../../utils/context/PatientsContext";
import PdfCard from "../../components/PdfCard";
import { PdfExam } from "../../components/PdfExam";

const MealPlanPdfsPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const mealPlanId = searchParams.get("meal_plan_id");
    const type = searchParams.get("type");
    const isOld = searchParams.get("is-old-plan");
    const spanDate = searchParams.get("span-date");

    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const navigate = useNavigate()

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);

    const [pdfs, setPdfs] = useState<PdfExam[]>()

    useEffect(() => {
        if (!patients) {
            setIsGeneralLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
        setIsGeneralLoading(false)

    }, [patients, patientId])

    const getMealPlanUrls = async () => {
        if (!patientId || !idToken) return
        const folder = `${mealPlanId}/${type}`
        try {
            const urls = await getMealPlanPdf(patientId, idToken, folder)
            setPdfs(urls)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getMealPlanUrls()
    }, [])

    if (isGeneralLoading || !pdfs) {
        return (
            <LoadingSpinner />
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-full max-w-[1068px] space-y-[20px] pb-[20px]">
                <BackButton
                    text={`Torna a ${patient.user_name} ${patient.user_family_name}`}
                    onClick={() => {
                        if (isOld === "false") {
                            navigate(`/platform-experts/customers/single-customer/view-meal-plan-page?patient_id=${patientId}`)
                        } else {
                            navigate(`/platform-experts/customers/single-customer/view-meal-plan-page/meal-plan-history/old-meal-plan?id=${mealPlanId}&span-date=${spanDate}&patient-id=${patientId}`)
                        }
                    }}
                />
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">{type === MealPlanPdfType.MealPlan ? "🥙 Dieta" : "🗂️ A supporto del Meal Plan"}</div>
                    </div>
                </div>
                {
                    pdfs.map((pdf, index) => (
                        <PdfCard key={index} exam={pdf} pdfType={type === MealPlanPdfType.MealPlan ? PdfType.Diet : PdfType.Uploads} />
                    ))
                }
            </div>
        </div>
    )
}

export default MealPlanPdfsPage

import { useParams } from 'react-router-dom';
import { ChatList } from '../../../features/chat/components/ChatList';
import { ChatSessionScreen } from '../../../features/chat/components/chatSession/ChatSessionScreen';
import useGetChatSessions from '../../../features/chat/hooks/useGetChatSessions';
import { ChatSession } from '../../../features/chat/types/ChatSession';
const Chat = () => {
  const { chatSessionId } = useParams();
  const {
    data: chatSessions,
    isLoading,
    isError,
    isFetching,
  } = useGetChatSessions();
  // TODO-TM: Improve UI
  if (isLoading) {
    return <div>Loading...</div>;
  }
  // TODO-TM: Improve UI
  if (isError) {
    return <div>Error loading chat sessions</div>;
  }
  if (!chatSessionId) {
    return <ChatList />;
  }
  const chatSession = chatSessions?.find(
    (session: ChatSession) => session.id === chatSessionId,
  );

  // TODO: handle case where chat session is not found
  if (!chatSession) {
    if (isFetching) {
      // TODO-TM: Improve UI
      return <div>Loading...</div>;
    }
    // TODO-TM: Improve UI
    return <div>Chat session not found</div>;
  }

  return (
    <div className='w-full h-full'>
      <ChatSessionScreen chatSession={chatSession} />
    </div>
  );
};

export default Chat;

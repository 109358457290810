import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpertRole } from "../Enums";
import { useAppointments } from "./AppointmentsContext";
import { usePatients } from "./PatientsContext";
import { useUserData } from "./UserDataContext";

interface AuthState {
    idToken: string | null;
    userId: string | null;
    accessToken: string | null;
    refreshToken: string | null;
    expiryTime: number | null;
    role: ExpertRole | null;
}

interface AuthContextType {
    auth: AuthState;
    login: (idToken: string, userId: string, accessToken: string, refreshToken: string, expiryIn: number, role: ExpertRole) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const isValidExpertRole = (role: string | null): role is ExpertRole => {
        return role !== null && Object.values(ExpertRole).includes(role as ExpertRole);
    };
    const navigate = useNavigate();
    const [auth, setAuth] = useState<AuthState>({
        idToken: localStorage.getItem('idToken'),
        userId: localStorage.getItem('userId'),
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        expiryTime: localStorage.getItem('expiryTime') ? parseInt(localStorage.getItem('expiryTime')!) : null,
        role: isValidExpertRole(localStorage.getItem('role')) ? (localStorage.getItem('role') as ExpertRole) : null,
    });

    useEffect(() => {
        // Ensure no null values are stored in localStorage
        if (auth.idToken) localStorage.setItem('idToken', auth.idToken);
        else localStorage.removeItem('idToken');

        if (auth.userId) localStorage.setItem('userId', auth.userId);
        else localStorage.removeItem('userId');

        if (auth.accessToken) localStorage.setItem('accessToken', auth.accessToken);
        else localStorage.removeItem('accessToken');

        if (auth.refreshToken) localStorage.setItem('refreshToken', auth.refreshToken);
        else localStorage.removeItem('refreshToken');

        if (auth.expiryTime) localStorage.setItem('expiryTime', auth.expiryTime.toString());
        else localStorage.removeItem('expiryTime');

        if (auth.role && isValidExpertRole(auth.role)) {
            localStorage.setItem('role', auth.role);
        } else {
            localStorage.removeItem('role');
        }
    }, [auth]);

    // Function to log in
    const login = (idToken: string, userId: string, accessToken: string, refreshToken: string, expiryIn: number, role: ExpertRole) => {
        if (!isValidExpertRole(role)) {
            console.error(`Invalid role provided: ${role}`);
            return;
        }
        const expiryTime = Date.now() + expiryIn * 1000; // Set expiry time in milliseconds
        setAuth({ idToken, userId, accessToken, refreshToken, expiryTime, role });
    };

    const { clearUserData } = useUserData();
    const { clearPatients } = usePatients()
    const { clearAppointments } = useAppointments()

    const logout = () => {
        setAuth({ idToken: null, userId: null, accessToken: null, refreshToken: null, expiryTime: null, role: null });
        // Remove all auth-related items from localStorage
        localStorage.removeItem('idToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiryTime');
        localStorage.removeItem('role');
        clearUserData()
        clearPatients()
        clearAppointments()
        navigate("/"); // Redirect to the home page or login page
        console.clear();
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
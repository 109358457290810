import { Patient } from "../../../utils/api-objects/Patient";

export const getPatientInfo = (patientId: string, patients: Patient[]) => {
    const patient = patients.find(patient => patient.user_id === patientId)
    let patientName;
    let patientFamilyName;
    let patientEmail;
    if (patient) {
        patientName = patient.user_name
        patientFamilyName = patient.user_family_name
        patientEmail = patient.email
    }
    return { patientName, patientFamilyName, patientEmail}
}
import { useState } from "react";

export const useMealPlanInputs = () => {
    const [inputs, setInputs] = useState<Record<string, string>>({
        guidelines: "",
        recepees: "",
        nutri_comment: "",
        food_tips: "",
        integrators_suggested: "",
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        // Ensure each new line starts with a bullet point
        const formattedValue = value
            .split("\n")
            .map((line) => (line.startsWith("•") ? line : `• ${line}`))
            .join("\n");

        setInputs((prev) => ({ ...prev, [name]: formattedValue }));
    };

    return { inputs, handleInputChange }
}


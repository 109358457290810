import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { HealthPlan, HealthPlanPdfType } from "../../../../../utils/api-objects/meal-plan"
import { handleDate } from "../../../../../utils/Functions"

export const HealthPlanSection: React.FC<{
    healthPlan: HealthPlan,
    showUpdate?: boolean,
    isOld?: boolean,
    spanDate?: string | null,
}> = ({
    healthPlan,
    showUpdate = true,
    isOld = false,
    spanDate,
}) => {
        const navigate = useNavigate()

        const handleNavigate = (pdfType: HealthPlanPdfType) => {
            // Filter the PDF data based on type (Materials or Prescriptions)

            // Pass the filtered PDF data as state to the next page
            navigate(`/platform-experts/customers/single-customer/view-health-plan-page/pdfs-page?patient_id=${healthPlan.user_id}&health_plan_id=${healthPlan.id}&type=${pdfType}&is-old-plan=${isOld}&span-date=${spanDate}`)
        }

        return (
            <div className="space-y-[20px]">
                <div className={`w-full flex items-center ${showUpdate ? "justify-between" : "justify-end"}`}>
                    {
                        showUpdate &&
                        <div>
                            Aggiornato il {handleDate(healthPlan.updated_at)}
                        </div>
                    }
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
                    {healthPlan.data.comment && <HealthPlanDataBox title="👩‍⚕️‍  Commento del medico" data={healthPlan.data.comment} />}
                    {healthPlan.data.food_suggested && <HealthPlanDataBox title="🥙  Alimenti consigliati" data={healthPlan.data.food_suggested.map(item => item.text)} />}
                    {healthPlan.data.integrators_suggested && <HealthPlanDataBox title="💊  Integratori consigliati" data={healthPlan.data.integrators_suggested.map(item => item.text)} />}
                    {healthPlan.data.check_ups_suggested && <HealthPlanDataBox title="🩺  Check-up consigliati" data={healthPlan.data.check_ups_suggested.map(item => item.text)} />}
                </div>
                {
                    healthPlan.pdf_data.length > 0 &&
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
                        {
                            healthPlan.pdf_data.some(pdf => pdf.type === HealthPlanPdfType.Materials) && (
                                <HealthPlanPdfFolder
                                    title={"🗂️ Materiali di approfondimento"}
                                    onClick={() => handleNavigate(HealthPlanPdfType.Materials)}
                                    number={healthPlan.pdf_data.filter(pdfs => pdfs.type === HealthPlanPdfType.Materials).length}
                                />
                            )
                        }
                        {
                            healthPlan.pdf_data.some(pdf => pdf.type === HealthPlanPdfType.Prescriptions) && (
                                <HealthPlanPdfFolder
                                    title={"📄 Prescrizioni"}
                                    onClick={() => handleNavigate(HealthPlanPdfType.Prescriptions)}
                                    number={healthPlan.pdf_data.filter(pdfs => pdfs.type === HealthPlanPdfType.Prescriptions).length}
                                />
                            )
                        }
                    </div>
                }
            </div>
        )
    }

export const HealthPlanDataBox: React.FC<{
    title: string,
    data: string[],
    deactivated?: boolean,
}> = ({
    title,
    data,
    deactivated = false,
}) => {
        const [isExpanded, setIsExpanded] = useState(false)

        // ********************************************************************************

        return (
            <div
                className={`w-full rounded-[15px] shadow-platform px-[20px] py-[10px] flex flex-col space-y-[20px] ${isExpanded ? "h-auto" : "h-[178px]"} ${deactivated ? "bg-holifya-grey opacity-60" : "bg-white"} `}

            >
                <div
                    className="font-bold text-[12px] leading-[33px]"
                >
                    {title}
                </div>

                <div
                    className={`relative overflow-hidden transition-all ${isExpanded ? "h-auto" : "max-h-[100px]"}`}
                >
                    {data.map((element, index) => (
                        <div key={index} className="font-normal text-[16px] leading-[24px]">
                            • {element}
                        </div>
                    ))}

                    {!isExpanded && (
                        <div className={`absolute bottom-0 left-0 w-full h-[40px] ${!deactivated ? "bg-gradient-to-t from-white to-transparent" : "bg-gradient-to-t from-holifya-grey to-transparent"} pointer-events-none`} />
                    )}
                </div>

                <div className="w-full">
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="text-holifya-blue font-bold text-[12px] leading-[17px]"
                    >
                        {isExpanded ? "Mostra meno" : "Mostra di più"}
                    </button>
                </div>
            </div>
        );
    }

const HealthPlanPdfFolder: React.FC<{
    title: string,
    onClick: () => void,
    number: number,
}> = ({
    title,
    onClick,
    number,
}) => {
        return (
            <div
                className="w-full h-[111px] rounded-[5px] shadow-platform px-[20px] py-[10px] cursor-pointer flex items-center justify-between"
                onClick={onClick}
            >
                <div className="font-bold text-[20px] leading-[33px]">
                    {title}
                </div>
                <div className="bg-holifya-red text-white font-bold w-[24px] h-[24px] flex items-center justify-center rounded-full text-[14px]">
                    {number}
                </div>
            </div>
        )
    }
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { callCreateMealPlanApi } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useFindPatient } from "../../../../utils/hooks/useFindPatient";
import { useMealPlanFileUpload } from "../../../../utils/hooks/useMealPlanFileUpload";
import { useMealPlanInputs } from "../../../../utils/hooks/useMealPlanInputs";
import { UploadSection } from "../components/UploadSection";
import { SuccessOverlay } from "../health-plan/components/SuccessOverlay";
import { mealPlanFieldsBase, mealPlanKeys } from "./components/constants";

const MealPlanPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const hasFood = searchParams.get("hasFood") === "true";
    const hasIntegrators = searchParams.get("hasIntegrators") === "true";

    const { patient, isGeneralLoading } = useFindPatient(patientId)
    const { uploadedFiles, onFilesSelect, onRemoveFile } = useMealPlanFileUpload()
    const { inputs, handleInputChange } = useMealPlanInputs()

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false)

    const mealPlanFields = mealPlanFieldsBase.filter(field =>
        (field.name !== "food_tips" || !hasFood) &&
        (field.name !== "integrators_suggested" || !hasIntegrators)
    );

    // ********************************************************************************

    const onPublishClick = async () => {
        submitMealPlan()
    }

    const submitMealPlan = async () => {
        if (!idToken || !patientId) return;
        setIsLoading(true);

        try {
            const mealPlanData = Object.fromEntries(
                Object.entries(inputs).map(([key, value]) => [
                    mealPlanKeys[key] || key,
                    value
                        ? value.split("\n")
                            .map((item) => item.replace(/^•\s*/, "").trim())
                            .filter(Boolean)
                        : null,
                ])
            );

            const formData = new FormData();
            formData.append("user_id", patientId);

            Object.entries(mealPlanData).forEach(([key, value]) => {
                if (value !== null) {
                    formData.append(key, Array.isArray(value) ? value.join("\n") : value);
                }
            });

            uploadedFiles.mealPlan.forEach((file) => formData.append("mealPlan", file));
            uploadedFiles.supportMaterials.forEach((file) => formData.append("supportMaterials", file));

            await callCreateMealPlanApi(idToken, formData);
            setSuccess(true);
        } catch (error) {
            console.error("Errore nella creazione del meal plan", error);
        } finally {
            setIsLoading(false);
        }
    };

    const isPublishEnabled = useMemo(() => {
        return Object.values(inputs).some((value) => value.trim() !== "") ||
            uploadedFiles.mealPlan.length > 0 ||
            uploadedFiles.supportMaterials.length > 0;
    }, [inputs, uploadedFiles]);

    if (isGeneralLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px] pr-[20px]">
            <div className="w-full space-y-[30px] pb-[20px] mt-[20px] max-w-[1068px]">
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">Meal Plan</div>
                    </div>
                    <div className="flex space-x-[20px]">
                        <div className="w-[266px] h-[50px]">
                            <PrimaryButton
                                text="Salva modifiche"
                                onClick={onPublishClick}
                                pointer={isPublishEnabled}
                                disabled={!isPublishEnabled}
                                uppercase={false}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="w-[266px] h-[50px]">
                            <WhiteButton
                                text={"Chiudi senza salvare"}
                                onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
                                uppercase={false}
                                border={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="space-y-[20px]">
                    {mealPlanFields.map((field) => (
                        <div key={field.name}>
                            <label htmlFor={field.name} className="block font-bold text-[16px] leading-[33px] mb-2">
                                {field.label} <span className="font-normal text-[12px]">{(field.name === "food_tips" || field.name === "integrators_suggested") && "(Non visibile all'utente se già compilati dal medico in Health Plan)"}</span>
                            </label>
                            <textarea
                                id={field.name}
                                name={field.name}
                                className="w-full h-[100px] p-[10px] border border-solid border-black rounded-[5px] font-normal text-[16px] leading-[20px] focus:outline-none resize-none placeholder:italic"
                                value={inputs[field.name as keyof typeof inputs] || ""}
                                onChange={handleInputChange}
                                placeholder={field.placeholder}
                            />
                        </div>
                    ))}
                </div>
                <div className="w-full grid grid-cols-2 gap-[20px] max-w-[1068px]">
                    <div className="w-full">
                        <UploadSection
                            title="Carica meal plan"
                            description="Nessun meal plan allegato"
                            onFilesSelect={(files) => onFilesSelect(files, "mealPlan")}
                            onRemoveFile={(index) => onRemoveFile("mealPlan", index)}
                            selectedFiles={uploadedFiles.mealPlan}
                        />
                    </div>
                    <div className="w-full">
                        <UploadSection
                            title="🗂️ A supporto del Meal Plan"
                            description="Nessuna file a supporto allegato a questo Meal Plan"
                            onFilesSelect={(files) => onFilesSelect(files, "supportMaterials")}
                            onRemoveFile={(index) => onRemoveFile("supportMaterials", index)}
                            selectedFiles={uploadedFiles.supportMaterials}
                        />
                    </div>
                </div>
            </div>
            {
                success &&
                <SuccessOverlay
                    onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
                    type="Meal Plan"
                />
            }
        </div>
    )
}

export default MealPlanPage

import { useEffect } from 'react';
import { create } from 'zustand';
import useGetChatSessions from '../../features/chat/hooks/useGetChatSessions';
import { ChatSession } from '../../features/chat/types/ChatSession';
import { useAuth } from '../context/AuthContext';

export const ChatNotificationsInitializer = () => {
  const { auth } = useAuth();
  const { refetch } = useGetChatSessions();

  useEffect(() => {
    if (auth.userId) {
      refetch();
    }
  }, [auth.userId, refetch]);

  return null; // This is a utility component that doesn't render anything
};

interface ChatNotificationsState {
  unreadSessions: ChatSession[];
  setUnreadSessions: (sessions: ChatSession[]) => void;
  clearUnreadSessions: () => void;
}

export const useChatNotificationsStore = create<ChatNotificationsState>(
  (set) => ({
    unreadSessions: [],
    setUnreadSessions: (sessions) => set({ unreadSessions: sessions }),
    clearUnreadSessions: () => set({ unreadSessions: [] }),
  }),
);

import { Route, Routes } from 'react-router-dom';
import './App.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ForgotPassword from './pages/loginFlow/forgotPassword/ForgotPassword';
import FetchAppointments from './pages/loginFlow/login/FetchAppointments';
import FetchPatients from './pages/loginFlow/login/FetchPatients';
import FetchUserData from './pages/loginFlow/login/FetchUserData';
import LoginPage from './pages/loginFlow/login/LoginPage';
import EmailRegistration from './pages/loginFlow/registration/EmailRegistration';
import EmailRegistrationCode from './pages/loginFlow/registration/EmailRegistrationCode';
import EmailRegistrationCompleteAccount from './pages/loginFlow/registration/EmailRegistrationCompleteAccount';
import WallPage from './pages/loginFlow/registration/WallPage';
import Account from './pages/platform/Account';
import Analytics from './pages/platform/analytics/Analytics';
import Events from './pages/platform/analytics/Sections/Events';
import Purchases from './pages/platform/analytics/Sections/Purchases';
import Appointments from './pages/platform/Appointments';
import Chat from './pages/platform/chat/Chat';
import Layout from './pages/platform/components/Layout';
import { NavBarElement } from './pages/platform/components/nav-bar-element.enum';
import Answers from './pages/platform/customers/Answers';
import AnswersCategory from './pages/platform/customers/AnswersCategory';
import BloodTestPage from './pages/platform/customers/BloodTestPage';
import CrossResultsPage from './pages/platform/customers/CrossResultsPage';
import Customers from './pages/platform/customers/Customers';
import DnaTestPage from './pages/platform/customers/DnaTestPage';
import EditHealthPlanPage from './pages/platform/customers/health-plan/EditHealthPlanPage';
import HealthPlanHistory from './pages/platform/customers/health-plan/HealthPlanHistory';
import HealthPlanPage from './pages/platform/customers/health-plan/HealthPlanPage';
import HealthPlanPdfsPage from './pages/platform/customers/health-plan/HealthPlanPdfsPage';
import OldHealthPlanPage from './pages/platform/customers/health-plan/OldHealthPlanPage';
import ViewHealthPlan from './pages/platform/customers/health-plan/ViewHealthPlan';
import EditMealPlanPage from './pages/platform/customers/meal-plan/EditMealPlanPage';
import MealPlanHistory from './pages/platform/customers/meal-plan/MealPlanHistory';
import MealPlanPage from './pages/platform/customers/meal-plan/MealPlanPage';
import MealPlanPdfsPage from './pages/platform/customers/meal-plan/MealPlanPdfsPage';
import OldMealPlanPage from './pages/platform/customers/meal-plan/OldMealPlanPage';
import ViewMealPlan from './pages/platform/customers/meal-plan/ViewMealPlan';
import SharedFromEquipe from './pages/platform/customers/SharedFromEquipe';
import SharedFromUser from './pages/platform/customers/SharedFromUser';
import SingleCustomer from './pages/platform/customers/SingleCustomer';
import Home from './pages/platform/Home';
import Material from './pages/platform/Material';
import { ChatNotificationsInitializer } from './utils/stores/useChatNotificationsStore';

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ChatNotificationsInitializer/>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/fetch-user-data' element={<FetchUserData />} />
        <Route path='/fetch-patients' element={<FetchPatients />} />
        <Route path='/fetch-appointments' element={<FetchAppointments />} />
        <Route path='/email-registration' element={<EmailRegistration />} />
        <Route path='/email-registration-admin' element={<EmailRegistration />} />
        <Route path='/email-registration-physician' element={<EmailRegistration />} />
        <Route path='/email-registration-code' element={<EmailRegistrationCode />} />
        <Route path='/email-registration-complete-account' element={<EmailRegistrationCompleteAccount />} />
        <Route
          path='/chat'
          element={
            <Layout currentItem={NavBarElement.Chat}>
              <Chat />
            </Layout>
          }
        />
        <Route
          path='chat-session'
          element={
            <Layout
              currentItem={NavBarElement.Home}
            >
              <Chat />
            </Layout>
          }
        >
          <Route path=':chatSessionId' element={<Chat />} />
        </Route>
        <Route path='/wall-page' element={<WallPage />} />
        <Route
          path='/platform-experts'
          element={
            <Layout currentItem={NavBarElement.Home}>
              <Home />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/home'
          element={
            <Layout currentItem={NavBarElement.Home}>
              <Home />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <Customers />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <SingleCustomer />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/answers'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <Answers />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/answers/category'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <AnswersCategory />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/dna-test-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <DnaTestPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/blood-test-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <BloodTestPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/cross-results-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <CrossResultsPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/shared-from-equipe-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <SharedFromEquipe />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/shared-from-customer-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <SharedFromUser />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/health-plan-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <HealthPlanPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-health-plan-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <ViewHealthPlan />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-health-plan-page/edit-health-plan'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <EditHealthPlanPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-health-plan-page/pdfs-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <HealthPlanPdfsPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-health-plan-page/health-plan-history'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <HealthPlanHistory />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-health-plan-page/health-plan-history/old-health-plan'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <OldHealthPlanPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/meal-plan-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <MealPlanPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-meal-plan-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <ViewMealPlan />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-meal-plan-page/edit-meal-plan'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <EditMealPlanPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-meal-plan-page/pdfs-page'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <MealPlanPdfsPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-meal-plan-page/meal-plan-history'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <MealPlanHistory />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/customers/single-customer/view-meal-plan-page/meal-plan-history/old-meal-plan'
          element={
            <Layout currentItem={NavBarElement.Customers}>
              <OldMealPlanPage />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/appointments'
          element={
            <Layout currentItem={NavBarElement.Appointements}>
              <Appointments />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/material'
          element={
            <Layout currentItem={NavBarElement.Material}>
              <Material />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/account'
          element={
            <Layout currentItem={NavBarElement.Account}>
              <Account />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/analytics'
          element={
            <Layout currentItem={NavBarElement.Analytics}>
              <Analytics />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/analytics/purchases'
          element={
            <Layout currentItem={NavBarElement.Analytics}>
              <Purchases />
            </Layout>
          }
        />
        <Route
          path='/platform-experts/analytics/events'
          element={
            <Layout currentItem={NavBarElement.Analytics}>
              <Events />
            </Layout>
          }
        />
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
      </QueryClientProvider>
  );
}

export default App;

import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { MealPlan } from "../../../../utils/api-objects/meal-plan";
import { callUpdateMealPlanDataApi, getMealPlanById } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useFindPatient } from "../../../../utils/hooks/useFindPatient";
import { SuccessOverlay } from "../health-plan/components/SuccessOverlay";
import { mealPlanFieldsBase, mealPlanKeys } from "./components/constants";

const EditMealPlanPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const planId = searchParams.get("id");
    const patientId = searchParams.get("patient-id");
    const hasFood = searchParams.get("hasFood") === "true";
    const hasIntegrators = searchParams.get("hasIntegrators") === "true";

    const [isLoading, setIsLoading] = useState(false)

    const { patient } = useFindPatient(patientId)
    const [mealPlan, setMealPlan] = useState<MealPlan>()

    const [isSuccess, setIsSuccess] = useState(false)
    const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false)

    const fetchMealPlan = useCallback(async () => {
        if (!planId || !idToken) return
        try {
            const mealPlanData = await getMealPlanById(planId, idToken)
            if (mealPlanData) {
                setMealPlan(mealPlanData)
                formatMealPlanFields(mealPlanData, hasFood, hasIntegrators)
            }
        } catch (error) {
            console.error("Error fetching meal plan:", error);
        }
    }, [])

    useEffect(() => {
        fetchMealPlan()
    }, [fetchMealPlan])

    useEffect(() => {
        if (mealPlan) {
            setInputs(formatMealPlanFields(mealPlan, hasFood, hasIntegrators));
        }
    }, [mealPlan]);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!isUpdateButtonEnabled) setIsUpdateButtonEnabled(true)
        const { name, value } = event.target;


        const formattedValue = value
            .split("\n")
            .map((line) => (line.startsWith("•") ? line : `• ${line}`))
            .join("\n");

        setInputs((prev) => ({
            ...prev,
            [name]: formattedValue,
        }));
    };

    const [inputs, setInputs] = useState<Record<string, string>>({});

    const mealPlanFields = mealPlanFieldsBase
    // .filter(field =>
    //     (field.name !== "food_tips" || !hasFood) &&
    //     (field.name !== "integrators_suggested" || !hasIntegrators)
    // );

    const onUpdateClick = async () => {
        if (!idToken || !patientId || !mealPlan) return;
        setIsLoading(true);

        // Convert `inputs` into the correct structure using `mealPlanKeys`
        const mealPlanData = Object.fromEntries(
            Object.entries(inputs).map(([key, value]) => {
                const mappedKey = mealPlanKeys[key]; // Ensure correct mapping

                if (mappedKey === "nutri_comment") {
                    return [mappedKey, value ? value.split("\n").map((item) => item.replace(/^•\s*/, "").trim()).filter(Boolean) : null];
                }

                return [
                    mappedKey,
                    value
                        ? value.split("\n").map((item) => ({
                            text: item.replace(/^•\s*/, "").trim(), // Remove bullet before saving
                            isChecked: false, // Default value
                        })).filter(item => item.text)
                        : null,
                ];
            })
        );

        try {
            await callUpdateMealPlanDataApi(idToken, mealPlan.id, patientId, mealPlanData);
            setIsSuccess(true);
        } catch (error) {
            console.error("Errore nell'aggiornamento del piano alimentare:", error);
        }

        setIsLoading(false);
    };

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Paziente non trovato
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px] pr-[20px]">
            <div className="w-full space-y-[30px] pb-[20px] mt-[20px] max-w-[1068px]">
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">Meal Plan</div>
                    </div>
                    <div className="flex space-x-[20px]">
                        <div className="w-[266px] h-[50px]">
                            <PrimaryButton
                                text="Salva modifiche"
                                onClick={onUpdateClick}
                                pointer={isUpdateButtonEnabled}
                                disabled={!isUpdateButtonEnabled}
                                uppercase={false}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="w-[266px] h-[50px]">
                            <WhiteButton
                                text={"Chiudi senza salvare"}
                                onClick={() => navigate(`/platform-experts/customers/single-customer/view-meal-plan-page?patient_id=${patientId}&hasFood=${hasFood}&hasIntegrators=${hasIntegrators}`)}
                                uppercase={false}
                                border={true}
                            />
                        </div>
                    </div>
                </div>
                {mealPlanFields.map((field) => (
                    <div key={field.name}>
                        <label htmlFor={field.name} className="block font-bold text-[16px] leading-[33px] mb-2">
                            {field.label} <span className="font-normal text-[12px]">{(field.name === "food_tips" || field.name === "integrators_suggested") && "(Non visibile all'utente se già compilati dal medico in Health Plan)"}</span>
                        </label>
                        <textarea
                            id={field.name}
                            name={field.name}
                            className={`w-full h-[100px] p-[10px] border border-solid border-black rounded-[5px] font-normal text-[16px] leading-[20px] focus:outline-none resize-none placeholder:italic ${field.name === "food_tips" && hasFood ? "bg-holifya-grey" : "bg-white"} ${field.name === "integrators_suggested" && hasIntegrators ? "bg-holifya-grey" : "bg-white"}`}
                            value={inputs[field.name as keyof typeof inputs] || ""}
                            onChange={handleInputChange}
                            placeholder={field.placeholder}
                        />
                    </div>
                ))}
            </div>
            {
                isSuccess &&
                <SuccessOverlay
                onClick={() => navigate(`/platform-experts/customers/single-customer/view-meal-plan-page?patient_id=${patientId}&hasFood=${hasFood}&hasIntegrators=${hasIntegrators}`)}
                    type="Meal Plan"
                    update={true}
                />
            }
        </div>
    )
}

export default EditMealPlanPage

const formatWithBullets = (items?: { text: string }[] | string[]) => {
    if (!items || !Array.isArray(items)) return ""; // Ensure it's an array before mapping
    return items.map(item => (typeof item === "string" ? `• ${item}` : `• ${item.text}`)).join("\n");
};

const formatMealPlanFields = (mealPlan: MealPlan, hasFood: boolean, hasIntegrators: boolean) => {
    const formattedData: Record<string, string> = {
        guidelines: formatWithBullets(mealPlan?.data?.guidelines || []),
        recepees: formatWithBullets(mealPlan?.data?.recepees || []),
        nutri_comment: formatWithBullets(mealPlan?.data?.nutri_comment || []),
        food_tips: formatWithBullets(mealPlan?.data?.food_tips || []),
        integrators_suggested: formatWithBullets(mealPlan?.data?.integrators_suggested || []),
    };

    // if (!hasFood) {
    //     formattedData.food_tips = formatWithBullets(mealPlan?.data?.food_tips || []);
    // }

    // if (!hasIntegrators) {
    //     formattedData.integrators_suggested = formatWithBullets(mealPlan?.data?.integrators_suggested || []);
    // }

    return formattedData;
};

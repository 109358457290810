import { useInfiniteQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../../utils/Constants';
import { useAuth } from '../../../utils/context/AuthContext';
import { Message } from '../types/Message';

const MESSAGES_PER_PAGE = 35;

const getMessages = async (
  chatSessionId: string,
  skip: number,
  limit: number,
  userId: string,
  userToken: string,
  shouldMarkAsRead: boolean = false,
): Promise<{
  messages: Message[];
  nextPage: number | null;
}> => {
  const response = await fetch(
    `${BASE_URL}/api/chat/sessions/${chatSessionId}/messages${
      shouldMarkAsRead
        ? `/read?skip=${skip}&limit=${limit}&requester_id=${userId}`
        : `?requester_id=${userId}`
    }`,
    {
      headers: {
        Authorization: userToken,
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch messages');
  }

  const data = await response.json();
  const messages = data as Message[];

  return {
    messages: messages,
    nextPage: messages.length === limit ? skip + limit : null,
  };
};

export const useMessages = (
  chatSessionId: string,
  shouldMarkAsRead: boolean = false,
  refetchInterval: number = 5000,
) => {
  const { auth } = useAuth();
  const userId = auth.userId;
  const userToken = auth.idToken;
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['messages', chatSessionId],
    queryFn: ({ pageParam = 0 }) =>
      getMessages(
        chatSessionId,
        pageParam,
        MESSAGES_PER_PAGE,
        userId!,
        userToken!,
        shouldMarkAsRead,
      ),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    initialPageParam: 0,
    select: (data) => ({
      pages: data.pages,
      pageParams: data.pageParams,
    }),
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: false,
  });

  return {
    messages: data?.pages.flatMap((page) => page.messages).reverse(),
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    refetch,
    refetchInterval,
  };
};

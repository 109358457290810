import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppointments } from '../../utils/context/AppointmentsContext';
import { useAuth } from '../../utils/context/AuthContext';
import { useUserData } from '../../utils/context/UserDataContext';
import {
  getFormattedDateWithInput,
  handleMissingDays,
} from '../../utils/Functions';
import { useChatNotificationsStore } from '../../utils/stores/useChatNotificationsStore';
import { NewAppointmentWidget } from './components/NewAppointmentWidget';

const Home = () => {
  const { auth } = useAuth();
  const idToken = auth.idToken;
  const { userData } = useUserData();
  const username = userData.name;
  const email = userData.email;
  const { appointmentsState } = useAppointments();
  const allFutureActiveAppointments =
    appointmentsState.allFutureActiveAppointments;
  const allMyFutureAppointments = allFutureActiveAppointments?.filter(
    (app) => app.expert_email === email,
  );

  const realName = username === 'Holifya' ? 'Holifya admin 💙' : username;

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { unreadSessions } = useChatNotificationsStore();

  // const onGetAllAnswersClick = async () => {
  //   if (!idToken) return
  //   try {
  //     setIsLoading(true)
  //     const answers = await migrateAnswers(idToken)
  //     console.log(answers)
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  if (!allMyFutureAppointments) {
    return (
      <div className='font-medium text-[20px] leading-[33px]'>
        C'è stato un errore nel recuperare gli appuntamenti. Ti chiediamo di
        riprovare.
      </div>
    );
  }

  if (allMyFutureAppointments.length === 0) {
    return (
      <div className='w-full h-full pl-[70px] mt-[30px] space-y-[20px]'>
        <div className='font-normal text-[20px] leading-[26px]'>
          Qui potrai visualizzare tutti gli aggiornamenti recenti dei tuoi
          clienti
        </div>
      </div>
    );
  }

  const futureDay = allMyFutureAppointments[0].date;
  const futureDayAppointments = allMyFutureAppointments.filter(
    (app) =>
      getFormattedDateWithInput(app.date) ===
      getFormattedDateWithInput(futureDay),
  );

  // ********************************************************************************

  return (
    <div className='w-full h-full flex flex-col pl-[70px]'>
      <div className='font-bold text-[35px] leading-[55px] my-[30px]'>
        Ciao {realName}!
      </div>
      <div className='w-full flex-1'>
        <div className='w-[89%] h-full space-y-[20px]'>
          <div className='font-bold text-[20px] leading-[33px]'>
            In programma {handleMissingDays(futureDayAppointments[0])}
          </div>
          {futureDayAppointments.map((app, index) => (
            <NewAppointmentWidget key={index} appointment={app} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { NavBarElement } from "../../../pages/platform/components/nav-bar-element.enum"
import { ENVIRONMENT, NEW_ACCOUNT_LOGO, NEW_ACCOUNT_LOGO_FOCUS, NEW_ANALYTICS_LOGO, NEW_ANALYTICS_LOGO_FOCUS, NEW_APPOINTMENTS_LOGO, NEW_APPOINTMENTS_LOGO_FOCUS, NEW_CHAT_LOGO, NEW_CHAT_LOGO_FOCUS, NEW_CUSTOMERS_LOGO, NEW_CUSTOMERS_LOGO_FOCUS, NEW_HOME_LOGO, NEW_HOME_LOGO_FOCUS, NEW_MATERIAL_LOGO, NEW_MATERIAL_LOGO_FOCUS } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { Environment, ExpertRole } from "../../../utils/Enums"

interface Props {
    currentItem: NavBarElement,
}

const NavBar: React.FC<Props> = ({
    currentItem,
}) => {
    const { auth } = useAuth()
    const role = auth.role

    const navigate = useNavigate()

    const logoMap = {
        [NavBarElement.Home]: { default: NEW_HOME_LOGO, focused: NEW_HOME_LOGO_FOCUS },
        [NavBarElement.Customers]: { default: NEW_CUSTOMERS_LOGO, focused: NEW_CUSTOMERS_LOGO_FOCUS },
        [NavBarElement.Chat]: { default: NEW_CHAT_LOGO, focused: NEW_CHAT_LOGO_FOCUS },
        [NavBarElement.Appointements]: { default: NEW_APPOINTMENTS_LOGO, focused: NEW_APPOINTMENTS_LOGO_FOCUS },
        [NavBarElement.Material]: { default: NEW_MATERIAL_LOGO, focused: NEW_MATERIAL_LOGO_FOCUS },
        [NavBarElement.Account]: { default: NEW_ACCOUNT_LOGO, focused: NEW_ACCOUNT_LOGO_FOCUS, },
        [NavBarElement.Analytics]: { default: NEW_ANALYTICS_LOGO, focused: NEW_ANALYTICS_LOGO_FOCUS },
    };

    const getFocusedLogo = (element: NavBarElement): string => logoMap[element]?.focused || "";
    const getDefaultLogo = (element: NavBarElement): string => logoMap[element]?.default || "";

    const navItems = [
        { element: NavBarElement.Home, title: "Home", path: "/platform-experts" },
        { element: NavBarElement.Customers, title: "Clienti", path: "/platform-experts/customers" },
        { element: NavBarElement.Chat, title: "Chat", path: "/chat" },
        ...(role !== ExpertRole.Admin ? [
            { element: NavBarElement.Appointements, title: "Appuntamenti", path: "/platform-experts/appointments" },
            { element: NavBarElement.Material, title: "Materiale", path: "/platform-experts/material" },
        ] : []),
        ...(role === ExpertRole.Admin ? [
            { element: NavBarElement.Analytics, title: "Analytics", path: "/platform-experts/analytics" },
        ] : []),
        { element: NavBarElement.Account, title: "Account", path: "/platform-experts/account" },
    ];

    const preloadImages = () => {
        Object.values(logoMap).forEach(({ default: defaultImage, focused: focusedImage }) => {
            new Image().src = defaultImage;
            new Image().src = focusedImage;
        });
    };

    useEffect(() => {
        preloadImages();
    }, [])

    // ********************************************************************************

    return (
        <div className={`w-full md:w-[100px] h-[90px] md:h-full flex items-center justify-center bg-holifya-navy-blue`}>
            <div className={`w-full flex md:flex-col justify-center md:items-center gap-[10px] md:gap-[36px]`}>
                {
                    role && role === ExpertRole.Admin && <div className="fixed top-0 text-white">ADMIN</div>
                }
                {
                    ENVIRONMENT === Environment.DEVELOP && role !== ExpertRole.Admin && <div className="fixed top-0 text-white">{role}</div>
                }
                {
                    navItems.map(({ element, title, path }) => (
                        <NavBarSingleElement
                            key={element}
                            image={currentItem === element ? getFocusedLogo(element) : getDefaultLogo(element)}
                            title={title}
                            onClick={() => navigate(path)}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default NavBar

interface ElementProps {
    image: string,
    title: string,
    onClick: () => void,
}

const NavBarSingleElement: React.FC<ElementProps> = ({
    image,
    title,
    onClick,
}) => {
    return (
        <div
            className="h-[48px] flex flex-col items-center cursor-pointer"
            onClick={onClick}
        >
            <div className="mb-[10px] h-[22px]">
                <img src={image} alt={title} />
            </div>
            <div className="font-bold text-[11px] leading-[16px] text-white">
                {title}
            </div>
        </div>
    )
}


export const Events = () => {
    return (
        <div className="space-y-[20px]">
            <div className="font-bold text-[14px] leading-[20px]">
                Eventi
            </div>
            <div className="">
                Eventi per il buon vecchio Pres @PietroLombardo
            </div>
        </div>
    )
}

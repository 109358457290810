import { PdfExam } from "../../pages/platform/components/PdfExam";
import { Answer } from "../api-objects/Answer";
import { Question } from "../api-objects/Question";
import { SurveyCompleted } from "../api-objects/SurveyCompleted";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { BucketFolder, QuestionCategory, SurveyType } from "../Enums";
import { Answers } from "../utils";
import { ErrorWithMessageCallback, GetAnswersSuccessCallback, GetExamsSuccessCallback, GetQuestionsSuccessCallback, GetSurveysCompletedCallback, SuccessWithMessageCallback } from "./callback-helpers";

export async function getAllExamsByType(
    user_id: string,
    id_token: string,
    type: BucketFolder,
    onSuccess: GetExamsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/emr/${user_id}/exams-urls/${type}`, requestOptionsGET(id_token))
        if (response.ok) {
            const exams: PdfExam[] = await response.json()
            onSuccess(exams)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get exams by type failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAllMealPlanPdfsByType(
    user_id: string,
    id_token: string,
    type: BucketFolder,
    onSuccess: GetExamsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/emr/${user_id}/exams-urls/meal-plan/${type}`, requestOptionsGET(id_token))
        if (response.ok) {
            const exams: PdfExam[] = await response.json()
            onSuccess(exams)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get exams by type failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getLastSurveyUpdateByUserId(
    user_id: string,
    id_token: string,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/surveys-completed/last-update/${user_id}`, requestOptionsGET(id_token))
        if (response.ok) {
            const date = await response.json()
            onSuccess(date.last_update)
        } else if (response.status === 404) {
            onError("No surveys found")
        }else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get survey failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getSurveysByType(
    id_token: string,
    type: SurveyType,
    onSuccess: GetSurveysCompletedCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/surveys-completed/survey-type/${type}`, requestOptionsGET(id_token))
        if (response.ok) {
            const surveys: SurveyCompleted[] = await response.json()
            onSuccess(surveys)
        } else if (response.status === 404) {
            onError("No surveys found")
        }else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get survey failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getSurveysByUserId(
    id_token: string,
    user_id: string,
    onSuccess: GetSurveysCompletedCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/surveys-completed/user-id/${user_id}`, requestOptionsGET(id_token))
        if (response.ok) {
            const surveys: SurveyCompleted[] = await response.json()
            onSuccess(surveys)
        } else if (response.status === 404) {
            onError("No surveys found")
        }else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get survey failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getQuestionByQuestionCategory(
    category: QuestionCategory,
    onSuccess: GetQuestionsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/questions/category/${category}`, {
            method: 'GET',
        })
        if (response.ok) {
            const questions: Question[] = await response.json()
            onSuccess(questions)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAnswersByIdsAndUserId(
    ids: string[],
    user_id: string,
    id_token: string,
    onSuccess: GetAnswersSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const idsString = ids.join(',');
        const response = await fetch(`${BASE_URL}/api/answers/user_id/${user_id}/ids/${encodeURIComponent(idsString)}/medical`, requestOptionsGET(id_token))
        if (response.ok) {
            const answersResponse: Answer[] = await response.json()
            onSuccess(answersResponse)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Get answers by user_id failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function saveMultipleAnswers(
    user_id: string,
    id_token: string,
    answers: Answers[],
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/multiple/${user_id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                answers,
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create question failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAllAnswers(
    id_token: string,
): Promise<any> {
    try {
        const response = await fetch(`${BASE_URL}/api/answers/all`, requestOptionsGET(id_token))
        if (response.ok) {
            const answers = await response.json()
            return answers
        } else if (response.status === 404) {
            console.warn("No answers found")
        } else {
            const error = await response.text()
            console.log("Errore generale:", error)
        }
    } catch (error) {
        console.error("Get answers failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

// export async function migrateAnswers(
//     id_token: string,
// ): Promise<any> {
//     try {
//         const response = await fetch(`${BASE_URL}/api/answers/migration`, requestOptionsGET(id_token))
//         if (response.ok) {
//             const answers = await response.json()
//             return answers
//         } else if (response.status === 404) {
//             console.warn("No answers found")
//         } else {
//             const error = await response.text()
//             console.log("Errore generale:", error)
//         }
//     } catch (error) {
//         console.error("Get answers failed:", error);
//         return { success: false, error: "An unexpected error occurred" };
//     }
// }

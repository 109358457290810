import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../../components/buttons/new/BackButton";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { MealPlan } from "../../../../utils/api-objects/meal-plan";
import { getMealPlansApi } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useFindPatient } from "../../../../utils/hooks/useFindPatient";
import MealPlanSection from "./components/MealPlanSection";

const ViewMealPlan = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const hasFood = searchParams.get("hasFood");
    const hasIntegrators = searchParams.get("hasIntegrators");

    const { patient, isGeneralLoading } = useFindPatient(patientId)

    const [mealPlans, setMealPlans] = useState<MealPlan[]>()

    const callGetMealPlans = async () => {
        if (!patientId || !idToken) {
            console.log(`patientId or idToken missing: patientId: ${patient}, idToken: ${idToken}`)
            return;
        }
        try {
            const mealPlans = await getMealPlansApi(idToken, patientId)
            setMealPlans(mealPlans)
        } catch {
            console.log("Errore nel recupero dell'health plan")
        }
    }

    useEffect(() => {
        callGetMealPlans()
    }, [])

    if (isGeneralLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    if (!mealPlans) {
        return (
            <div></div>
        )
    }

    const onEditMealPlanClicked = () => {
        navigate(`edit-meal-plan?patient-id=${patientId}&id=${mealPlans[0].id}&hasFood=${hasFood}&hasIntegrators=${hasIntegrators}`)
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px] pr-[20px]">
            <div className="w-full max-w-[1068px] space-y-[20px] pb-[20px]">
                <BackButton
                    text={`Torna a ${patient.user_name} ${patient.user_family_name}`}
                    onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
                />
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">Meal Plan</div>
                    </div>
                    <div className="flex space-x-[20px]">
                        <div className="w-[266px] h-[50px]">
                            <PrimaryButton
                                text="Modifica"
                                onClick={onEditMealPlanClicked}
                                pointer={true}
                                disabled={false}
                                uppercase={false}
                            />
                        </div>
                    </div>
                </div>
                <MealPlanSection mealPlan={mealPlans[0]} hasFood={hasFood ? hasFood === "true" : false} hasIntegrators={hasIntegrators ? hasIntegrators === "true" : false} />
                {
                    mealPlans.length > 1 && <div className="space-y-[20px]">
                        <div className="w-full h-[1px] bg-black" />
                        <div
                            className={`w-full h-[90px] flex items-center rounded-[5px] shadow-platform cursor-pointer px-[20px] py-[10px]`}
                            onClick={() => navigate(`meal-plan-history?patient_id=${patientId}`)}
                        >
                            <div className="w-full h-full flex items-center justify-between space-x-[10px] overflow-hidden">
                                <div
                                    className={`font-bold text-[20px] leading-[33px]`}
                                >
                                    Meal Plan passati
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default ViewMealPlan

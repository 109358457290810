import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from '../../../utils/Constants';
import { useAuth } from '../../../utils/context/AuthContext';
import { ChatSession } from '../types/ChatSession';
const createSession = async ({
  user_id,
  id_token,
  expertId,
  expertType,
}: {
  user_id: string;
  id_token: string;
  expertId: string;
  expertType: string;
}) => {
  const response = await fetch(`${BASE_URL}/api/chat/sessions`, {
    method: 'POST',
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: id_token,
      // 'Access-Control-Allow-Origin': 'http://localhost:3000',
    },
    body: JSON.stringify({
      user_id,
      expert_id: expertId,
      expert_type: expertType,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to create session');
  }

  return response.json() as Promise<ChatSession>;
};

export const useCreateSession = (userId: string, expertType: string) => {
  const { auth } = useAuth();
  const expertId = auth.userId;
  const idToken = auth.idToken;
  const queryClient = useQueryClient();
  const { mutate, isPending, data, isError, isSuccess } = useMutation({
    mutationFn: () =>
      createSession({
        user_id: userId,
        id_token: idToken!,
        expertId: expertId!,
        expertType: expertType,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['chatSessions'] });
    },
  });

  return { mutate, isPending, data, isError, isSuccess };
};

import { useEffect, useState } from "react";
import { Patient } from "../api-objects/Patient";
import { useAuth } from "../context/AuthContext";
import { usePatients } from "../context/PatientsContext";

export const useFindPatient = (patientId: string | null) => {
    const { auth } = useAuth();
    const idToken = auth.idToken;
    const { patientsState } = usePatients();
    const patients = patientsState.patients;

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);

    useEffect(() => {
        if (!patients || !patientId) {
            setIsGeneralLoading(false);
            return;
        }

        const foundPatient = patients.find((p) => p.user_id === patientId);
        setPatient(foundPatient || null);
        setIsGeneralLoading(false);
    }, [patients, patientId]);

    return { patient, isGeneralLoading };
};
import { useEffect, useRef, useState } from "react";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import NavBar from "../../../features/navbar/components/NavBar";
import { getAppointmentsByOrganizerEmail2 } from "../../../utils/apis/booking-service";
import { getPatientsApi } from "../../../utils/apis/medical-user-service";
import { useAppointments } from "../../../utils/context/AppointmentsContext";
import { useAuth } from "../../../utils/context/AuthContext";
import { usePatients } from "../../../utils/context/PatientsContext";
import { useUserData } from "../../../utils/context/UserDataContext";
import { AppointmentStatus } from "../../../utils/Enums";
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../../utils/Functions";
import { Appointment } from "./Appointment";
import { NavBarElement } from "./nav-bar-element.enum";
import SessionExpiredDialog from "./SessionExpiryDialog";

interface LayoutProps {
  currentItem: NavBarElement;
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({
  currentItem,
  children,
}) => {
  const { auth } = useAuth()
  const { userData } = useUserData()
  const { setPatients } = usePatients()
  const {
    setAppointments,
    setActiveAppointments,
    setFutureActiveAppointments,
    setOldActiveAppointments,
  } = useAppointments()

  const idToken = auth.idToken
  const userId = auth.userId
  const role = auth.role
  const email = userData.email

  const [shouldShowExpirySessionDialog, setShouldShowExpirySessionDialog] = useState(false)
  const [isAuthLoading, setIsAuthLoading] = useState(true)

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchSessionAndAppointments = async () => {
      if (!idToken || !userId || !email || !role) return;

      try {
        const patients = await getPatientsApi(idToken, userId, role, handleSessionExpiry);
        if (patients) {
          setPatients(patients)
        }

        const appointments = await getAppointmentsByOrganizerEmail2(idToken, email, handleSessionExpiry);
        if (appointments) {
          const allAppointments: Appointment[] = appointments.map((appointment) => ({
            date: appointment.payload.scheduled_event.start_time,
            patientName: appointment.payload.name,
            patientEmail: appointment.email,
            editUrl: appointment.payload.reschedule_url,
            connectUrl: appointment.payload.scheduled_event.location.join_url,
            cancelUrl: appointment.payload.cancel_url,
            remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
            remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
            remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
            status: appointment.payload.status,
            expert_email: appointment.organizer_email,
          }));

          const allActiveAppointments = allAppointments.filter(
            (app) => app.status === AppointmentStatus.Active
          );
          const allFutureActiveAppointments = allActiveAppointments.filter(
            (app) => app.remainingMinutes > -30
          );
          const allOldActiveAppointments = allActiveAppointments.filter(
            (app) => app.remainingMinutes <= -30
          );

          setAppointments(allAppointments);
          setActiveAppointments(allActiveAppointments);
          setFutureActiveAppointments(allFutureActiveAppointments);
          setOldActiveAppointments(allOldActiveAppointments);
        }
      } catch (error) {
        console.error("Unexpected error occurred:", error);
      } finally {
        setIsAuthLoading(false);
      }
    };

    const handleSessionExpiry = () => {
      console.warn("Session expired. Redirecting to login...");
      setShouldShowExpirySessionDialog(true);
    };

    fetchSessionAndAppointments();

    const intervalId = setInterval(fetchSessionAndAppointments, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [idToken, userId, email]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [children]);

  // ********************************************************************************

  return (
    <div className="w-dvh h-dvh flex flex-col-reverse md:flex-row">
      <NavBar currentItem={currentItem} />
      <div className="flex-1 h-full flex flex-col pt-[70px]">
        <div className="pl-[20px] pr-[20px] md:pl-[70px]">
          <HolifyaFullLogo />
        </div>
        <div ref={contentRef} className="flex-1 w-full overflow-y-auto scrollbar-hide">
          {children}
        </div>
      </div>
      {
        shouldShowExpirySessionDialog && <SessionExpiredDialog />
      }
      {
        isAuthLoading && <LoadingSpinner />
      }
    </div>
  )
}

export default Layout

import { useAuth } from "../../../utils/context/AuthContext";
import { usePatients } from "../../../utils/context/PatientsContext";
import { ExpertRole } from "../../../utils/Enums";
import { ChatPreview } from "./ChatPreview";

export const ChatList = () => {
  const { auth } = useAuth()
  const role = auth.role
  const { patientsState } = usePatients()
  const patients = patientsState.patients

  // Apply filtering only if role is Admin or Specialist
  const filteredPatients =
    role === ExpertRole.Admin
      ? patients?.filter(
        (patient) =>
          patient.sub_type !== null ||
          patient.products?.includes("metabolic-hook")
      )
      : role === ExpertRole.Specialist ?
        patients?.filter((patient) => patient.sub_type !== null && patient.sub_type !== "sub-metabolic-health" && patient.sub_type !== "sub-metabolic-health-lite" && !patient.products.includes("metabolic-hook")) : patients;

  return (
    <div className='w-full h-full space-y-[20px] pl-[70px]'>
      <div className='w-full flex items-center justify-between'>
        <div className='flex items-center'>
          <div className='font-bold text-[22px] leading-[33px]'>Chat</div>
        </div>
      </div>
      <div className='space-y-[20px] max-w-[800px]'>
        {
          filteredPatients && filteredPatients.map((patient, index) =>
            <ChatPreview patient={patient} key={index} />
          )
        }
      </div>
    </div>
  );
};

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../../components/buttons/new/BackButton"
import { LoadingSpinner } from "../../../../components/LoadingSpinner"
import { TempStat } from "../../../../utils/api-objects/TempStat"
import { getAllStats } from "../../../../utils/apis/user-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { HealthPlanStats, LoginStats, MealPlanStats } from "./StatBoxes"

const Events = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const navigate = useNavigate()

    const [allStats, setAllStats] = useState<TempStat[]>()

    useEffect(() => {
        if (!idToken) return
        const fetchStats = async () => {
            try {
                const stats = await getAllStats(idToken)
                setAllStats(stats)
            } catch (error) {
                console.log(error)
            }
        }
        fetchStats()
    }, [idToken])

    if (!allStats) {
        return <LoadingSpinner />
    }

    return (
        <div className="w-full h-full flex flex-col pl-[70px] max-w-[1068px] space-y-[20px]">
            <div className="flex items-center justify-between mb-6">
                <div className="font-bold text-3xl">Eventi</div>
                <BackButton onClick={() => navigate("/platform-experts/analytics")} text="Torna a Analytics" />
            </div>
            <LoginStats stats={allStats} />
            <HealthPlanStats stats={allStats} />
            <MealPlanStats stats={allStats} />
        </div>
    )
}

export default Events

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { HealthPlan } from "../../../../utils/api-objects/meal-plan";
import { Patient } from "../../../../utils/api-objects/Patient";
import { callUpdateHealthPlanDataApi, getHealthPlansById } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { usePatients } from "../../../../utils/context/PatientsContext";
import { fields } from "./components/constants";
import { SuccessOverlay } from "./components/SuccessOverlay";

const EditHealthPlanPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const planId = searchParams.get("id");
    const patientId = searchParams.get("patient-id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [isLoading, setIsLoading] = useState(false)

    const [patient, setPatient] = useState<Patient | null>(null);
    const [healthPlan, setHealthPlan] = useState<HealthPlan>()

    const [isSuccess, setIsSuccess] = useState(false)
    const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState(false)

    const getHealthPlanByHpId = async () => {
        try {
            const hp = await getHealthPlansById(planId!, idToken!)
            hp && setHealthPlan(hp)
        } catch {
            console.log("errore")
        }
    }

    useEffect(() => {
        if (!patientId) return
        if (!patients) {
            setIsLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
    }, [patients, patientId])

    useEffect(() => {
        if (!planId || !idToken) return
        getHealthPlanByHpId()
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!isUpdateButtonEnabled) setIsUpdateButtonEnabled(true)
        const { name, value } = event.target;
    
        
        const formattedValue = value
            .split("\n")
            .map((line) => (line.startsWith("•") ? line : `• ${line}`))
            .join("\n");
    
        setInputs((prev) => ({
            ...prev,
            [name]: formattedValue,
        }));
    };

    const [inputs, setInputs] = useState<Record<string, string>>({
        commento: "",
        alimenti: "",
        integratori: "",
        checkup: "",
    });

    useEffect(() => {
        if (healthPlan) {
            setInputs({
                commento: formatWithBullets(healthPlan.data.comment),
                alimenti: formatWithBullets(healthPlan.data.food_suggested?.map(info => info.text)),
                integratori: formatWithBullets(healthPlan.data.integrators_suggested?.map(info => info.text)),
                checkup: formatWithBullets(healthPlan.data.check_ups_suggested?.map(info => info.text)),
            });
        }
    }, [healthPlan]);

    const onUpdateClick = async () => {
        if (!idToken || !patientId || !healthPlan) return;
        setIsLoading(true);

        const healthPlanData: { [key: string]: any } = Object.fromEntries(
            Object.entries(inputs).map(([key, value]) => {
                const mappedKey =
                    key === "commento" ? "comment" :
                    key === "alimenti" ? "food_suggested" :
                    key === "integratori" ? "integrators_suggested" :
                    "check_ups_suggested";
        
                // If it's comment, keep as a list of strings
                if (mappedKey === "comment") {
                    return [mappedKey, value ? value.split("\n").map((item) => item.replace(/^•\s*/, "").trim()).filter(Boolean) : null];
                }
        
                // Convert the list into CheckableItem objects
                return [mappedKey, value ? value.split("\n").map((item) => ({
                    text: item.replace(/^•\s*/, "").trim(), // Remove bullet before saving
                    isChecked: false // Default value for isChecked
                })).filter(item => item.text) : null];
            })
        );

        try {
            const creation = await callUpdateHealthPlanDataApi(idToken, healthPlan.id, patientId, healthPlanData);
            setIsSuccess(true)
            console.log(creation)
        } catch {
            console.log("Errore nell'aggiornamento dell'health plan")
        }
        setIsLoading(false);
    };

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Paziente non trovato
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-full space-y-[30px] pb-[20px] mt-[20px] max-w-[1068px]">
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">Health Plan</div>
                    </div>
                    <div className="flex space-x-[20px]">
                        <div className="w-[266px] h-[50px]">
                            <PrimaryButton
                                text="Salva modifiche"
                                onClick={onUpdateClick}
                                pointer={isUpdateButtonEnabled}
                                disabled={!isUpdateButtonEnabled}
                                uppercase={false}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="w-[266px] h-[50px]">
                            <WhiteButton
                                text={"Chiudi senza salvare"}
                                onClick={() => navigate(`/platform-experts/customers/single-customer/view-health-plan-page?patient_id=${patientId}`)}
                                uppercase={false}
                                border={true}
                            />
                        </div>
                    </div>
                </div>
                {fields.map((field) => (
                    <div key={field.name}>
                        <label htmlFor={field.name} className="block font-bold text-[16px] leading-[33px] mb-2">
                            {field.label}
                        </label>
                        <textarea
                            id={field.name}
                            name={field.name}
                            className="w-full h-[100px] p-[10px] border border-solid border-black rounded-[5px] font-normal text-[16px] leading-[20px] focus:outline-none resize-none placeholder:italic"
                            value={inputs[field.name as keyof typeof inputs] || ""}
                            onChange={handleInputChange}
                            placeholder={field.placeholder}
                        />
                    </div>
                ))}
            </div>
            {
                isSuccess &&
                <SuccessOverlay
                    onClick={() => navigate(`/platform-experts/customers/single-customer/view-health-plan-page?patient_id=${patientId}`)}
                    type="Health Plan"
                    update={true}
                />
            }
        </div>
    )
}

export default EditHealthPlanPage

const formatWithBullets = (items?: string[]) => {
    if (!items || items.length === 0) return "";
    return items.map(item => `• ${item}`).join("\n");
};

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useEffect } from 'react';
import { BASE_URL } from '../../../utils/Constants';
import { useAuth } from '../../../utils/context/AuthContext';
import { useChatNotificationsStore } from '../../../utils/stores/useChatNotificationsStore';
import { ChatSession } from '../types/ChatSession';

async function getChatSessions(userId: string, userToken: string) {
  const response = await fetch(
    `${BASE_URL}/api/chat/expert/${userId}/sessions`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userToken,
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch chat sessions');
  }

  return response.json() as Promise<ChatSession[]>;
}

const useGetChatSessions = () => {
  const { auth } = useAuth();
  const userId = auth.userId;
  const userToken = auth.idToken;
  const queryClient = useQueryClient();
  const setUnreadSessions = useChatNotificationsStore(
    (state) => state.setUnreadSessions,
  );

  const { data, isLoading, error, isError, refetch, isFetching } = useQuery({
    queryKey: ['chatSessions', userId],
    queryFn: () => getChatSessions(userId!, userToken!),
    enabled: !!userId,
    refetchInterval: 60 * 1000,
    staleTime: 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });

  useEffect(() => {
    if (data) {
      // Check for unread messages
      const unreadSessions = data.filter(
        (session: ChatSession) =>
          new Date(session.last_message_sent_at) >
          new Date(session.last_read_expert),
      );
      unreadSessions.forEach((session: ChatSession) => {
        queryClient.invalidateQueries({ queryKey: ['messages', session.id] });
      });
      setUnreadSessions(unreadSessions);
    }
  }, [data, setUnreadSessions, queryClient, userId]);

  return { data, isLoading, error, isError, refetch, isFetching };
};

export default useGetChatSessions;

export const mealPlanFieldsBase = [
    { name: "nutri_comment", label: "👨‍⚕️ Commento del nutrizionista", placeholder: "Ogni a capo crea un elenco" },
    { name: "food_tips", label: "🥙  Alimenti consigliati", placeholder: "Ogni a capo crea un elenco" },
    { name: "integrators_suggested", label: "💊  Integratori consigliati", placeholder: "Ogni a capo crea un elenco" },
    { name: "guidelines", label: "🍲 Linee guida alimentari principali", placeholder: "Ogni a capo crea un elenco" },
    { name: "recepees", label: "🍳 Ricette e accostamenti suggeriti", placeholder: "Ogni a capo crea un elenco" },
];

export const mealPlanKeys: { [key: string]: string } = {
    guidelines: "guidelines",
    recepees: "recepees",
    nutri_comment: "nutri_comment",
    food_tips: "food_tips",
    integrators_suggested: "integrators_suggested"
};

import { useState } from "react";

export const useMealPlanFileUpload = () => {
    const [uploadedFiles, setUploadedFiles] = useState({
        mealPlan: [] as File[],
        supportMaterials: [] as File[],
    });

    const onFilesSelect = (files: File[], field: "mealPlan" | "supportMaterials") => {
        setUploadedFiles((prev) => ({
            ...prev,
            [field]: [...prev[field], ...files],
        }));
    };

    const onRemoveFile = (field: "mealPlan" | "supportMaterials", index: number) => {
        setUploadedFiles((prev) => ({
            ...prev,
            [field]: prev[field].filter((_, i) => i !== index),
        }));
    };

    return { uploadedFiles, onFilesSelect, onRemoveFile };
};
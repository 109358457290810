import { useNavigate } from "react-router-dom"
import { MealPlan, MealPlanPdfType } from "../../../../../utils/api-objects/meal-plan"
import { handleDate } from "../../../../../utils/Functions"
import { HealthPlanDataBox } from "../../health-plan/components/HealthPlanSection"

interface MealPlanSectionProps {
    mealPlan: MealPlan,
    isOld?: boolean,
    spanDate?: string | null,
    hasFood?: boolean,
    hasIntegrators?: boolean,
}

const MealPlanSection = ({ mealPlan, isOld = false, spanDate, hasFood = false, hasIntegrators = false, }: MealPlanSectionProps) => {
    const navigate = useNavigate()
    const handleNavigate = (pdfType: MealPlanPdfType) => {

        // Pass the filtered PDF data as state to the next page
        navigate(`/platform-experts/customers/single-customer/view-meal-plan-page/pdfs-page?patient_id=${mealPlan.user_id}&meal_plan_id=${mealPlan.id}&type=${pdfType}&is-old-plan=${isOld}&span-date=${spanDate}`)
    }

    return (
        <div className="space-y-[20px]">
            <div className={`w-full flex items-center justify-between`}>
                <div>
                    Aggiornato il {handleDate(mealPlan.updated_at)}
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
                {mealPlan.data.nutri_comment && mealPlan.data.nutri_comment.length > 0 && <HealthPlanDataBox title="👨‍⚕️ Commento del nutrizionista" data={mealPlan.data.nutri_comment} />}
                {mealPlan.data.food_tips && mealPlan.data.food_tips.length > 0 && <HealthPlanDataBox title="🥙  Alimenti consigliati" data={mealPlan.data.food_tips.map(item => item.text)} deactivated={hasFood} />}
                {mealPlan.data.integrators_suggested && mealPlan.data.integrators_suggested.length > 0 && <HealthPlanDataBox title="💊 Integratori consigliati" data={mealPlan.data.integrators_suggested.map(item => item.text)} deactivated={hasIntegrators} />}
                {mealPlan.data.guidelines && mealPlan.data.guidelines.length > 0 && <HealthPlanDataBox title="🍲 Linee guida alimentari principali" data={mealPlan.data.guidelines.map(item => item.text)} />}
                {mealPlan.data.recepees && mealPlan.data.recepees.length > 0 && <HealthPlanDataBox title="🍳 Ricette e accostamenti suggeriti" data={mealPlan.data.recepees.map(item => item.text)} />}
                {mealPlan.data.nutrition_recommendations && <HealthPlanDataBox title="👨‍⚕️ Commento del nutrizionista" data={mealPlan.data.nutrition_recommendations} />}
                {mealPlan.data.top_hero_foods && mealPlan.data.top_hero_foods.length > 0 && <HealthPlanDataBox title="🥙  Alimenti consigliati" data={mealPlan.data.top_hero_foods} />}
                {mealPlan.data.top_integratori && <HealthPlanDataBox title="💊 Integratori consigliati" data={mealPlan.data.top_integratori} />}
                {mealPlan.data.comment && mealPlan.data.comment.length > 0 && <HealthPlanDataBox title={"👨‍⚕️ Commento del nutrizionista"} data={[mealPlan.data.comment]} />}
                {mealPlan.data.important_key_points && mealPlan.data.important_key_points.length > 0 && <HealthPlanDataBox title={"Important key points"} data={mealPlan.data.important_key_points} />}
            </div>
            {
                mealPlan.pdf_data.length > 0 &&
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
                    {
                        mealPlan.pdf_data.some(pdf => pdf.type === MealPlanPdfType.MealPlan) && (
                            <MealPlanPdfFolder
                                title={"🥙 Dieta"}
                                onClick={() => handleNavigate(MealPlanPdfType.MealPlan)}
                                number={mealPlan.pdf_data.filter(pdfs => pdfs.type === MealPlanPdfType.MealPlan).length}
                            />
                        )
                    }
                    {
                        mealPlan.pdf_data.some(pdf => pdf.type === MealPlanPdfType.SupportMaterials) && (
                            <MealPlanPdfFolder
                                title={"🗂️ A supporto del Meal Plan"}
                                onClick={() => handleNavigate(MealPlanPdfType.SupportMaterials)}
                                number={mealPlan.pdf_data.filter(pdfs => pdfs.type === MealPlanPdfType.SupportMaterials).length}
                            />
                        )
                    }
                </div>
            }
        </div>
    )
}

export default MealPlanSection

const MealPlanPdfFolder: React.FC<{
    title: string,
    onClick: () => void,
    number: number,
}> = ({
    title,
    onClick,
    number,
}) => {
        return (
            <div
                className="w-full h-[111px] rounded-[5px] shadow-platform px-[20px] py-[10px] cursor-pointer flex items-center justify-between"
                onClick={onClick}
            >
                <div className="font-bold text-[20px] leading-[33px]">
                    {title}
                </div>
                <div className="bg-holifya-red text-white font-bold w-[24px] h-[24px] flex items-center justify-center rounded-full text-[14px]">
                    {number}
                </div>
            </div>
        )
    }

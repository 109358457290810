import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../utils/context/AuthContext';
import { Message } from '../../types/Message';
import { DateSeparator } from './DateSeparator';
import { MessageBubble } from './MessageBubble';

interface Props {
  messages: Message[];
  isLoading: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
}

const ChatBox: React.FC<Props> = ({
  messages,
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}) => {
  const { auth } = useAuth();
  const currentUserId = auth.userId;
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [hasInitialLoadCompleted, setHasInitialLoadCompleted] = useState(false);
  const isInitialLoadRef = useRef(true);
  const [lastMessageId, setLastMessageId] = useState<string | null>(
    messages[messages.length - 1]?.id ?? null,
  );
  const lastFetchTimeRef = useRef<number>(0);
  const FETCH_COOLDOWN = 1000; // 1 second cooldown between fetches

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      const now = Date.now();
      const chatBox = chatBoxRef.current;

      if (!chatBox) return;

      if (
        target.isIntersecting &&
        hasNextPage &&
        !isFetchingNextPage &&
        now - lastFetchTimeRef.current > FETCH_COOLDOWN
      ) {
        lastFetchTimeRef.current = now;
        const anchorMessageId = messages[0]?.id;

        if (anchorMessageId) {
          fetchNextPage?.();

          // After fetching, apply the bounce effect
          const applyBounceEffect = () => {
            if (!chatBox) return;
            chatBox.scrollBy({
              top: 400,
              behavior: 'smooth',
            });
          };

          // Wait a bit for the new messages to render before bouncing
          setTimeout(applyBounceEffect, 100);
        } else {
          fetchNextPage?.();
        }
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, messages],
  );

  useEffect(() => {
    if (!hasInitialLoadCompleted) return;
    const element = observerRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0,
      root: chatBoxRef.current,
      rootMargin: '50px 0px 0px 0px',
    });
    observer.observe(element);

    return () => observer.disconnect();
  }, [handleObserver, hasInitialLoadCompleted]);

  useEffect(() => {
    setLastMessageId(messages[messages.length - 1]?.id ?? null);
    if (
      lastMessageRef.current &&
      (isInitialLoadRef.current ||
        lastMessageId !== messages[messages.length - 1]?.id)
    ) {
      const chatBox = chatBoxRef.current;
      if (!chatBox) return;

      const handleScrollEnd = () => {
        isInitialLoadRef.current = false;
        setHasInitialLoadCompleted(true);
        chatBox.removeEventListener('scrollend', handleScrollEnd);
      };

      chatBox.addEventListener('scrollend', handleScrollEnd);

      // On initial load, scroll without animation
      if (isInitialLoadRef.current) {
        lastMessageRef.current.scrollIntoView();
      } else {
        // For subsequent updates, use smooth scrolling
        lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [messages, lastMessageId]);

  // Aggiungiamo una funzione helper per formattare la data in italiano
  const formatDateItalian = (date: Date) => {
    return date.toLocaleDateString('it-IT', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  // TODO-TM: Improve Loading UI
  if (isLoading) {
    return (
      <div className='w-full h-full p-6 flex flex-col gap-2'>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div
      ref={chatBoxRef}
      className='w-full h-full py-6 pl-[75px] pr-[70px] flex flex-col gap-2 overflow-y-auto'
    >
      <div ref={observerRef} style={{ height: '20px', minHeight: '20px' }}>
        {isFetchingNextPage && <p>Loading more messages...</p>}
      </div>
      {messages.length > 0 ? (
        <>
          {messages.reduce((acc: JSX.Element[], message, index) => {
            const currentDate = new Date(message.created_at);
            const prevMessage = messages[index - 1];
            const nextMessage = messages[index + 1];
            const prevDate = prevMessage
              ? new Date(prevMessage.created_at)
              : null;
            const nextDate = nextMessage ? new Date(nextMessage.created_at) : null;

            // Check if we need a date separator
            if (
              !prevDate ||
              currentDate.toDateString() !== prevDate.toDateString()
            ) {
              acc.push(
                <DateSeparator
                  key={`date-${message.created_at}`}
                  date={currentDate}
                  formatDate={formatDateItalian}
                />,
              );
            }

            const showTime =
              !nextDate || // Show time if it's the last message
              currentDate.getHours() !== nextDate.getHours() || // Show if hour is different
              currentDate.getMinutes() !== nextDate.getMinutes() || // OR if the next message is in a different hour
              message.sender_id !== nextMessage?.sender_id; // Show if the sender is different

            acc.push(
              <div key={message.id} data-message-id={message.id}>
                <MessageBubble
                  message={message}
                  isUser={message?.sender_id === currentUserId}
                  showTime={showTime}
                />
              </div>,
            );

            return acc;
          }, [])}
        </>
      ) : (
        <p>No messages yet</p>
      )}
      <div id={'lastMessage'} ref={lastMessageRef} />
    </div>
  );
};

export default ChatBox;

import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseButton from '../../../../components/buttons/new/CloseButton';
import { usePatients } from '../../../../utils/context/PatientsContext';
import useGetChatSessions from '../../hooks/useGetChatSessions';
import { useMessages } from '../../hooks/useMessages';
import { ChatSession } from '../../types/ChatSession';
import { getPatientInfo } from '../../utils/getPatientInfo';
import ChatBox from './ChatBox';
import { SendBar } from './SendBar';

interface Props {
  chatSession: ChatSession;
}

export const ChatSessionScreen: FC<Props> = ({ chatSession }) => {
  const navigate = useNavigate();
  const { refetch } = useGetChatSessions();
  const { patientsState } = usePatients()
  const patients = patientsState.patients || []

  const {
    messages,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
  } = useMessages(chatSession.id, true);

  useEffect(() => {
    refetch();
  }, [refetch]);

  // TODO: Improve Error UI
  if (isError) {
    return <div>Error loading messages</div>;
  }

  const { patientName, patientFamilyName, patientEmail } = getPatientInfo(chatSession.user_id, patients)

  return (
    <div className='w-full h-full flex flex-col pb-[10px]'>
      <div className='w-full h-full flex flex-col'>
        {/* HEADER */}
        <div className='w-full flex items-start justify-between shadow-lg py-[8px] pl-[75px] pr-[70px]'>
          <div className='flex items-center mb-[5px]'>
            <div className='bg-holifya-grey w-[46px] h-[46px] rounded-[23px] mr-[10px] flex items-center justify-center text-holifya-blue'>{patientName?.substring(0, 1)}</div>
            <div className='flex flex-col'>
              <p className='text-[22px] leading-[33px] font-bold'>
                {patientName} {patientFamilyName}
              </p>
              <p className='font-semibold text-[12px] text-holifya-blue'>
                {patientEmail}
              </p>
            </div>
          </div>
          <CloseButton onClick={() => navigate('/chat')} />
        </div>

        {/* CHATBOX */}
        <ChatBox
          messages={messages ?? []}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
        />

        {/* SENDBAR */}
        <SendBar chatSessionId={chatSession.id} />
      </div>
    </div>
  );
};
